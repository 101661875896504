import * as classNames from 'classnames';
import * as React from 'react';
import './Spinner.scss';

type SpinnerProps = React.HTMLAttributes<HTMLDivElement> & {
  message?: string;
  testId?: string;
};

export const Spinner = ({ className, message, testId, ...rest }: SpinnerProps) => {
  function getClassNames() {
    return classNames('Spinner', className);
  }

  return (
    <div className="Spinner__container" data-testid={testId && `${testId}-container`}>
      <div className={getClassNames()} data-testid={testId} {...rest}></div>
      {message && (
        <div className="Spinner__message" data-testid={testId && `${testId}-message`}>
          {message}
        </div>
      )}
    </div>
  );
};
