import * as React from 'react';
import { MilestoneModel, TestFlowModel, TestStepModel } from '../api';
import { Milestone, TestFlow } from '../types';

type MilestoneContextType = {
  milestone?: Milestone;
  testFlows: TestFlow[];
  loadData: () => void;
  moveTestStep: (step_id: string, params: { direction?: string; to?: string }) => void;
  updateTestStep: (step_id: string, params: { description?: string; type?: string }) => void;
  addTestStep: (flow_id: string, data: any) => void;
  deleteTestStep: (step_id: string) => void;
  moveTestFlow: (milestone_id: string, params: { direction?: string; to?: string }) => void;
  updateTestFlow: (flow_id: string, description: string) => void;
  addTestFlow: (milestone_id: string, name: string, callback?: (res: any) => void) => void;
  deleteTestFlow: (flow_id: string) => void;
};

const MilestoneContext = React.createContext<MilestoneContextType>({
  milestone: undefined,
  testFlows: [],
  // testSteps: [],
  loadData: () => {},
  moveTestStep: () => {},
  updateTestStep: () => {},
  addTestStep: () => {},
  deleteTestStep: () => {},
  moveTestFlow: () => {},
  updateTestFlow: () => {},
  addTestFlow: () => {},
  deleteTestFlow: () => {},
});

export const MilestoneProvider = (props: { children: React.ReactNode; id: string }) => {
  const [milestone, setMilestone] = React.useState<Milestone>();
  const [testFlows, setTestFlows] = React.useState<TestFlow[]>([]);

  function loadData() {
    MilestoneModel.get(props.id).then((milestone) => {
      setMilestone(milestone.data);
      TestFlowModel.list({ milestone: props.id }).then((res) => {
        setTestFlows(res.data.results);
      });
    });
  }

  function moveTestStep(step_id: string, params: { direction?: string; to?: string }) {
    TestStepModel.detailAction(step_id, 'move', 'post', { direction: params.direction, to: params.to }).then((res) => {
      loadData();
    });
  }

  function updateTestStep(step_id: string, params: { description?: string; type?: string }) {
    TestStepModel.update(step_id, { description: params.description, type: params.type }).then((res) => {
      loadData();
    });
  }

  function addTestStep(test_flow: string, data: any) {
    TestStepModel.create({ ...data, test_flow }).then((res) => {
      loadData();
    });
  }

  function deleteTestStep(step_id: string) {
    TestStepModel.delete(step_id).then(() => {
      loadData();
    });
  }

  function moveTestFlow(flow_id: string, params: { direction?: string; to?: string }) {
    TestFlowModel.detailAction(flow_id, 'move', 'post', { direction: params.direction, to: params.to }).then((res) => {
      setTestFlows(res.data);
    });
  }

  function updateTestFlow(flow_id: string, description: string) {
    TestFlowModel.update(flow_id, { name: description }).then((res) => {
      setTestFlows((prev) => prev.map((f) => (f.id === flow_id ? res.data : f)));
    });
  }

  function addTestFlow(milestone: string, name: string, callback?: (res: any) => void) {
    TestFlowModel.create({ name, milestone }).then((res) => {
      setTestFlows((prev) => [...prev, res.data]);
      callback?.(res);
    });
  }

  function deleteTestFlow(flow_id: string) {
    TestFlowModel.delete(flow_id).then((res) => {
      setTestFlows((prev) => prev.filter((f) => f.id !== flow_id));
    });
  }

  const data = React.useMemo<MilestoneContextType>(
    () => ({
      milestone,
      testFlows,
      loadData,
      moveTestStep,
      updateTestStep,
      addTestStep,
      deleteTestStep,
      moveTestFlow,
      updateTestFlow,
      addTestFlow,
      deleteTestFlow,
    }),
    [milestone, testFlows]
  );

  return <MilestoneContext.Provider value={data}>{props.children}</MilestoneContext.Provider>;
};

export const useMilestoneState = () => React.useContext(MilestoneContext);
