import * as React from 'react';
import { Helmet } from 'react-helmet';
import {
  Badge,
  Button,
  Callout,
  Card,
  Checkbox,
  Container,
  DatePicker,
  Dialog,
  Drawer,
  IconButton,
  Input,
  Menu,
  MenuItem,
  MultiSelect,
  Popover,
  Radio,
  SearchPopover,
  Select,
  Spinner,
  TabItem,
  Tabs,
  Textarea,
  Toggle,
  useToast,
} from '~/src/ui';
import './StyleGuide.scss';

export const StyleGuide = () => {
  const tabs = [
    'Badge',
    'Button',
    'Callout',
    'Card',
    'Checkbox',
    'DatePicker',
    'Dialog',
    'Drawer',
    'IconButton',
    'Input',
    'Menu',
    'MultiSelect',
    'Popover',
    'Radio',
    'SearchPopover',
    'Select',
    'Spinner',
    'Tabs',
    'Textarea',
    'Toaster',
    'Toggle',
  ];

  const [activeTab, setActiveTab] = React.useState('Badge');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [searchPopoverOpen, setSearchPopoverOpen] = React.useState(false);
  const [searchPopoverSelected, setSearchPopoverSelected] = React.useState<any>();
  const { success, error, warning, info } = useToast();

  return (
    <>
      <Helmet>
        <title>Style Guide | Start Studio Portal</title>
      </Helmet>
      <div className="StyleGuide mt-12">
        <Container>
          <Card fluid>
            <h2 className="mb-8">Style Guide</h2>
            <Tabs className="mb-8">
              {tabs.map((tab, index) => (
                <TabItem
                  key={index}
                  active={activeTab === tab}
                  onClick={() => {
                    setActiveTab(tab);
                  }}
                >
                  {tab}
                </TabItem>
              ))}
            </Tabs>
            <h3 className="mb-8">{activeTab}</h3>
            {activeTab === 'Badge' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content">
                    <div className="flex gap-4">
                      <Badge>Default</Badge>
                      <Badge color="black">Black</Badge>
                      <Badge color="red">Red</Badge>
                      <Badge color="yellow">Yellow</Badge>
                      <Badge color="green">Green</Badge>
                      <Badge color="blue">Blue</Badge>
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Button' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content gap-4">
                    <h4>Variants</h4>
                    <div className="flex gap-4">
                      <Button>Default</Button>
                      <Button variant="outlined">Outlined</Button>
                      <Button variant="raised">Raised</Button>
                    </div>
                    <div className="flex gap-4">
                      <Button color="primary">Default</Button>
                      <Button color="primary" variant="outlined">
                        Outlined
                      </Button>
                      <Button color="primary" variant="raised">
                        Raised
                      </Button>
                    </div>
                    <h4>Colors</h4>
                    <div className="flex gap-4">
                      <Button>Default</Button>
                      <Button color="black">Black</Button>
                      <Button color="primary">Primary</Button>
                      <Button color="red">Red</Button>
                      <Button color="yellow">Yellow</Button>
                      <Button color="green">Green</Button>
                      <Button color="blue">Blue</Button>
                    </div>
                    <h4>Sizes</h4>
                    <div>
                      <Button className="mr-4" size="sm" variant="raised">
                        Button
                      </Button>
                      <Button className="mr-4" variant="raised">
                        Button
                      </Button>
                      <Button size="lg" variant="raised">
                        Button
                      </Button>
                    </div>
                    <h4>Icons</h4>
                    <div className="flex gap-4">
                      <Button iconLeading="edit" variant="raised">
                        Button
                      </Button>
                      <Button iconTrailing="edit" variant="raised">
                        Button
                      </Button>
                    </div>
                    <h4>Rounded</h4>
                    <div className="flex gap-4">
                      <Button variant="raised">Button</Button>
                      <Button rounded variant="raised">
                        Button
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Callout' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content gap-4">
                    <h4>Default</h4>
                    <Callout>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla rhoncus tempor neque, sed malesuada
                      eros dapibus vel. Aliquam in ligula vitae tortor porttitor laoreet iaculis finibus est.
                    </Callout>
                    <h4>Header</h4>
                    <Callout header="Callout header">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla rhoncus tempor neque, sed malesuada
                      eros dapibus vel. Aliquam in ligula vitae tortor porttitor laoreet iaculis finibus est.
                    </Callout>
                    <h4>Icon</h4>
                    <Callout header="Callout header" icon="help">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla rhoncus tempor neque, sed malesuada
                      eros dapibus vel. Aliquam in ligula vitae tortor porttitor laoreet iaculis finibus est.
                    </Callout>
                    <h4>Dismissable</h4>
                    <Callout
                      header="Callout header"
                      onDismiss={() => {
                        console.log('onDismiss');
                      }}
                    >
                      Has a close button
                    </Callout>
                    <h4>Black</h4>
                    <Callout
                      color="black"
                      header="Callout header"
                      icon="help"
                      onDismiss={() => {
                        console.log('onDismiss');
                      }}
                    >
                      Black
                    </Callout>
                    <h4>Red</h4>
                    <Callout
                      color="red"
                      header="Callout header"
                      icon="help"
                      onDismiss={() => {
                        console.log('onDismiss');
                      }}
                    >
                      Red
                    </Callout>
                    <h4>Yellow</h4>
                    <Callout
                      color="yellow"
                      header="Callout header"
                      icon="help"
                      onDismiss={() => {
                        console.log('onDismiss');
                      }}
                    >
                      Yellow
                    </Callout>
                    <h4>Green</h4>
                    <Callout
                      color="green"
                      header="Callout header"
                      icon="help"
                      onDismiss={() => {
                        console.log('onDismiss');
                      }}
                    >
                      Green
                    </Callout>
                    <h4>Blue</h4>
                    <Callout
                      color="blue"
                      header="Callout header"
                      icon="help"
                      onDismiss={() => {
                        console.log('onDismiss');
                      }}
                    >
                      Blue
                    </Callout>
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Card' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content gap-4">
                    <h4>Default</h4>
                    <Card>
                      <h4>Header</h4>
                      <p>Content</p>
                    </Card>
                    <h4>Square</h4>
                    <Card rounded={false}>
                      <h4>Header</h4>
                      <p>Content</p>
                    </Card>
                    <h4>Shadow Small</h4>
                    <Card shadow="sm">
                      <h4>Header</h4>
                      <p>Content</p>
                    </Card>
                    <h4>Shadow Medium</h4>
                    <Card shadow="md">
                      <h4>Header</h4>
                      <p>Content</p>
                    </Card>
                    <h4>Shadow Large</h4>
                    <Card shadow="lg">
                      <h4>Header</h4>
                      <p>Content</p>
                    </Card>
                    <h4>Fluid</h4>
                    <Card fluid>
                      <h4>Header</h4>
                      <p>Content</p>
                    </Card>
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Checkbox' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content">
                    <Checkbox label="Option 1" />
                    <Checkbox label="Option 2" />
                    <Checkbox label="Option 3" />
                    <Checkbox disabled label="Option 4" />
                  </div>
                </div>
              </>
            )}
            {activeTab === 'DatePicker' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content">
                    <DatePicker />
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Dialog' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content">
                    <Button
                      onClick={() => {
                        setDialogOpen(true);
                      }}
                      variant="raised"
                    >
                      Open Dialog
                    </Button>
                    <Dialog
                      isOpen={dialogOpen}
                      onClose={() => {
                        setDialogOpen(false);
                      }}
                      style={{ width: '320px' }}
                    >
                      <h1>Dialog</h1>
                      <p>Content</p>
                    </Dialog>
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Drawer' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content">
                    <Button
                      onClick={() => {
                        setDrawerOpen(true);
                      }}
                      variant="raised"
                    >
                      Open Drawer
                    </Button>
                    <Drawer
                      isOpen={drawerOpen}
                      onClose={() => {
                        setDrawerOpen(false);
                      }}
                      style={{ padding: '1.5rem' }}
                    >
                      <h1>Drawer</h1>
                      <p>Content</p>
                    </Drawer>
                  </div>
                </div>
              </>
            )}
            {activeTab === 'IconButton' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content">
                    <IconButton>
                      <span className="material-icons">edit</span>
                    </IconButton>
                    <IconButton color="black">
                      <span className="material-icons">edit</span>
                    </IconButton>
                    <IconButton color="primary">
                      <span className="material-icons">edit</span>
                    </IconButton>
                    <IconButton color="red">
                      <span className="material-icons">edit</span>
                    </IconButton>
                    <IconButton color="yellow">
                      <span className="material-icons">edit</span>
                    </IconButton>
                    <IconButton color="green">
                      <span className="material-icons">edit</span>
                    </IconButton>
                    <IconButton color="blue">
                      <span className="material-icons">edit</span>
                    </IconButton>
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Input' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content gap-4">
                    <h4>Default</h4>
                    <Input />
                    <h4>Small</h4>
                    <Input inputSize="sm" />
                    <h4>Large</h4>
                    <Input inputSize="lg" />
                    <h4>Icon Leading</h4>
                    <Input iconLeading="search" />
                    <h4>Icon Trailing</h4>
                    <Input iconTrailing="search" />
                    <h4>Disabled</h4>
                    <Input disabled />
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Menu' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content">
                    <Menu>
                      <MenuItem iconLeading="content_copy">Copy</MenuItem>
                      <MenuItem iconLeading="edit">Edit</MenuItem>
                      <MenuItem iconLeading="settings">Settings</MenuItem>
                      <div className="divider"></div>
                      <MenuItem color="red" iconLeading="delete">
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </>
            )}
            {activeTab === 'MultiSelect' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content">
                    <MultiSelect
                      options={[
                        { label: 'Option 1', value: 'option-1' },
                        { label: 'Option 2', value: 'option-2' },
                      ]}
                      popoverProps={{ style: { width: '100%' } }}
                    />
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Popover' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content">
                    <div style={{ position: 'relative' }}>
                      <Button
                        onClick={() => {
                          setPopoverOpen(true);
                        }}
                        variant="raised"
                      >
                        Open Popover
                      </Button>
                      <Popover
                        isOpen={popoverOpen}
                        onClose={() => {
                          setPopoverOpen(false);
                        }}
                      >
                        <h4>Header</h4>
                        <p>Content</p>
                      </Popover>
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Radio' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content">
                    <Radio name="radio" label="Option 1" value="option1" />
                    <Radio name="radio" label="Option 2" value="option2" />
                    <Radio name="radio" label="Option 3" value="option3" />
                    <Radio disabled name="radio" label="Option 4" value="option4" />
                  </div>
                </div>
              </>
            )}
            {activeTab === 'SearchPopover' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content">
                    <div className="relative">
                      <Button
                        onClick={() => {
                          setSearchPopoverOpen(true);
                        }}
                        variant="raised"
                      >
                        Open Search Popover
                      </Button>
                      <SearchPopover
                        endpoint="/api/users/"
                        isOpen={searchPopoverOpen}
                        onChange={(selected) => {
                          setSearchPopoverSelected(selected);
                        }}
                        onClose={() => {
                          setSearchPopoverOpen(false);
                        }}
                        parameter="search"
                        renderMatch={(match) => {
                          return <>{match.email}</>;
                        }}
                        searchLabel="Search users"
                      />
                    </div>
                    <p>Selected: {searchPopoverSelected ? searchPopoverSelected.email : 'None'}</p>
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Select' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content">
                    <Select>
                      <option value="Beef">Beef</option>
                      <option value="Pork">Pork</option>
                      <option value="Chicken">Chicken</option>
                    </Select>
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Spinner' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content gap-4">
                    <h4>Default</h4>
                    <Spinner />
                    <h4>Message</h4>
                    <Spinner message="Fetching Updates..." />
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Tabs' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content">
                    <Tabs>
                      <TabItem active>Tab 1</TabItem>
                      <TabItem>Tab 2</TabItem>
                      <TabItem>Tab 3</TabItem>
                    </Tabs>
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Textarea' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content gap-4">
                    <h4>Default</h4>
                    <Textarea />
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Toaster' && (
              <>
                <p className="mb-4">Click to trigger toast</p>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content">
                    <div className="flex gap-4">
                      <Button
                        onClick={() => {
                          info('Info');
                        }}
                        variant="raised"
                      >
                        Info
                      </Button>
                      <Button
                        onClick={() => {
                          success('Success');
                        }}
                        variant="raised"
                      >
                        Success
                      </Button>
                      <Button
                        onClick={() => {
                          warning('Warning');
                        }}
                        variant="raised"
                      >
                        Warning
                      </Button>
                      <Button
                        onClick={() => {
                          error('Error');
                        }}
                        variant="raised"
                      >
                        Error
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeTab === 'Toggle' && (
              <>
                <div className="StyleGuide__example">
                  <div className="StyleGuide__example__content gap-4">
                    <h4>Default</h4>
                    <Toggle />
                    <h4>Label</h4>
                    <Toggle label="Left" />
                    <Toggle label="Right" labelPosition="right" />
                    <h4>Disabled</h4>
                    <Toggle checked disabled />
                  </div>
                </div>
              </>
            )}
          </Card>
        </Container>
      </div>
    </>
  );
};
