import { parse } from 'date-fns';

export function formatPhoneNumber(phoneNumber: string): string {
  if (!phoneNumber) {
    return '';
  }

  // Remove all non-numeric characters from the phone number
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Check the length of the cleaned phone number
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return '';
}

export function parseDateString(dateString: string) {
  return parse(dateString, 'yyyy-MM-dd', new Date());
}

export function toFixed2(floatString: string) {
  return parseFloat(floatString).toFixed(2);
}
