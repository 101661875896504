import * as React from 'react';
import { useForm } from 'react-hook-form';
import { http } from '~/src/http';
import { Button, Input } from '~/src/ui';

type ForgotPasswordFormData = {
  email: string;
};

const defaultFormData: ForgotPasswordFormData = {
  email: '',
};

export const ForgotPasswordForm = () => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [submitted, setSubmitted] = React.useState(false);

  const { formState, handleSubmit, register } = useForm<ForgotPasswordFormData>({
    defaultValues: defaultFormData,
  });

  const onSubmit = handleSubmit((data) => {
    setErrorMessage('');
    http
      .post('/api/send-password-reset-email/', data)
      .then(() => {
        setSubmitted(true);
      })
      .catch((err) => {
        if (!err.response.data) {
          return;
        }

        if (err.response.data.non_field_errors) {
          setErrorMessage(err.response.data.non_field_errors[0]);
        } else {
          setErrorMessage('An error occurred. Please try again.');
        }
      });
  });

  if (submitted) {
    return <p className="text-center my-8">Please check your email for a password reset link.</p>;
  }

  return (
    <form onSubmit={onSubmit}>
      <Input fluid placeholder="Email" type="email" {...register('email', { required: true })} />
      {errorMessage && <p className="error">{errorMessage}</p>}
      <Button className="mt-4" color="primary" disabled={!formState.isValid} fluid type="submit" variant="raised">
        Send Recovery Link
      </Button>
    </form>
  );
};
