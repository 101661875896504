export type User = {
  id: string;
  last_login: string | null;
  is_superuser: boolean;
  email: string;
  first_name: string;
  last_name: string;
  image: string | null;
  preferred_name: string;
  gender: 'f' | 'm' | null;
  birthdate: string | null;
  phone: string;
  date_joined: string;
  is_active: boolean;
  is_staff: boolean;
  is_developer: boolean;
  groups: any[];
  user_permissions: any[];
};
