import * as React from 'react';
import { Spinner } from '~/src/ui';
import { UserModel } from '../../api';
import { User } from '../../types';

type AuthContextType = {
  user: User | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
};

export const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

export type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = (props: AuthProviderProps) => {
  const token = localStorage.getItem('token');

  const [user, setUser] = React.useState<User>();
  const [loadingUser, setLoadingUser] = React.useState(false);

  React.useEffect(() => {
    if (token) {
      setLoadingUser(true);
      UserModel.listAction('me', 'get')
        .then((res) => {
          setUser(res.data);
        })
        .catch(() => {
          localStorage.clear();
          setUser(undefined);
        })
        .finally(() => {
          setLoadingUser(false);
        });
    }
  }, []);

  if (token && (!user || loadingUser)) {
    return (
      <div className="mt-12">
        <Spinner message="Loading..." />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export type MockedAuthProviderProps = {
  children: React.ReactNode;
  user?: User;
};

export const MockedAuthProvider = (props: MockedAuthProviderProps) => {
  const [user, setUser] = React.useState(props.user ? props.user : undefined);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
