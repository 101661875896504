import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from '~/src/ui';
import './Home.scss';

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard | Start Studio Portal</title>
      </Helmet>
      <Container>
        <h1 className="text-center">Welcome to Start Studio Portal</h1>
      </Container>
    </>
  );
};
