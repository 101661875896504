import * as classNames from 'classnames';
import * as React from 'react';
import { IconButton } from '~/src/ui';
import './Dialog.scss';

export type DialogProps = React.HTMLAttributes<HTMLDialogElement> & {
  backdrop?: boolean;
  closeButton?: boolean;
  closeOnBackdropClick?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
};

export const Dialog = ({
  backdrop = true,
  children,
  closeButton = true,
  closeOnBackdropClick = true,
  isOpen,
  onClose,
  ...rest
}: DialogProps) => {
  const dialogRef = React.useRef<HTMLDialogElement>(null);

  React.useEffect(() => {
    if (isOpen) {
      if (dialogRef.current?.showModal) {
        dialogRef.current?.showModal();
      }
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  function backdropClick(e: React.MouseEvent<HTMLDialogElement, MouseEvent>) {
    if (!dialogRef.current || !closeOnBackdropClick) {
      return;
    }

    const dialogRect = dialogRef.current?.getBoundingClientRect();
    const clickedInDialog =
      dialogRect.top <= e.clientY &&
      e.clientY <= dialogRect.top + dialogRect.height &&
      dialogRect.left <= e.clientX &&
      e.clientX <= dialogRect.left + dialogRect.width;

    if (!clickedInDialog && onClose) {
      onClose();
    }
  }

  return (
    <>
      {isOpen && (
        <dialog
          className={classNames('Dialog', {
            'Dialog--backdrop': backdrop,
          })}
          onClick={backdropClick}
          ref={dialogRef}
          {...rest}
        >
          {closeButton && (
            <IconButton
              className="Dialog__close"
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          )}
          {children}
        </dialog>
      )}
    </>
  );
};
