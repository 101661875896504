import * as classNames from 'classnames';
import * as React from 'react';
import { To } from 'react-router';
import { Link } from 'react-router-dom';
import './IconButton.scss';

type IconButtonColor =
  | 'default'
  | 'black'
  | 'primary'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'indigo'
  | 'violet';

type IconButton = React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> & {
  color?: IconButtonColor;
  navigateTo?: To;
  testId?: string;
};

export const IconButton = ({
  className,
  color = 'default',
  navigateTo,
  testId,
  type = 'button',
  ...rest
}: IconButton) => {
  function getClassNames() {
    return classNames(
      {
        IconButton: true,
        [`IconButton--color--${color}`]: color,
      },
      className
    );
  }

  if (navigateTo) {
    return <Link className={getClassNames()} data-testid={testId} to={navigateTo} type={type} {...rest}></Link>;
  }

  return <button className={getClassNames()} data-testid={testId} type={type} {...rest}></button>;
};
