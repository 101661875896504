import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { ConfirmDialog } from '~/src/components';
import { useAuth } from '~/src/features/auth';
import { Button, Card, Container, IconButton, Spinner } from '~/src/ui';
import { EstimateItemModel, EstimateModel, EstimateSectionModel } from '../../api';
import { EstimateItemDrawer, EstimateSectionDialog } from '../../components';
import { Estimate, EstimateItem, EstimateSection } from '../../types';
import './EstimateDetail.scss';

export const EstimateDetail = () => {
  const [estimate, setEstimate] = React.useState<Estimate>();
  const [estimateSections, setEstimateSections] = React.useState<EstimateSection[]>([]);
  const [estimateItemDrawer, setEstimateItemDrawer] = React.useState<{
    isOpen: boolean;
    item?: EstimateItem;
    section?: EstimateSection;
  }>({
    isOpen: false,
    item: undefined,
    section: undefined,
  });
  const [estimateSectionDialog, setEstimateSectionDialog] = React.useState<{
    isOpen: boolean;
    estimate?: Estimate;
    section?: EstimateSection;
  }>({
    isOpen: false,
    estimate: undefined,
    section: undefined,
  });
  const [deleteSectionDialog, setDeleteSectionDialog] = React.useState<{
    isOpen: boolean;
    section?: EstimateSection;
  }>({
    isOpen: false,
    section: undefined,
  });

  const { estimateId } = useParams();
  const { user } = useAuth();

  React.useEffect(() => {
    if (!estimateId) {
      return;
    }
    EstimateModel.get(estimateId).then((estimate) => {
      setEstimate(estimate.data);
      EstimateSectionModel.all({
        estimate: estimateId,
      }).then((sections) => {
        setEstimateSections(sections);
      });
    });
  }, [estimateId]);

  function sectionHoursHigh(section: EstimateSection) {
    return section.items.reduce((total, item) => {
      return total + parseFloat(item.hours_high);
    }, 0);
  }

  function totalHoursHigh() {
    return estimateSections.reduce((total, section) => {
      return (
        total +
        section.items.reduce((total, item) => {
          return total + parseFloat(item.hours_high);
        }, 0)
      );
    }, 0);
  }

  function clickCreateItem(section: EstimateSection) {
    setEstimateItemDrawer({
      isOpen: true,
      item: undefined,
      section,
    });
  }

  function clickUpdateItem(section: EstimateSection, item: EstimateItem) {
    setEstimateItemDrawer({
      isOpen: true,
      item,
      section,
    });
  }

  function closeItemDrawer() {
    setEstimateItemDrawer({
      isOpen: false,
      item: undefined,
      section: undefined,
    });
  }

  function clickCreateSection() {
    setEstimateSectionDialog({
      isOpen: true,
      section: undefined,
      estimate,
    });
  }

  function clickUpdateSection(section: EstimateSection) {
    setEstimateSectionDialog({
      isOpen: true,
      section,
      estimate,
    });
  }

  function clickDeleteSection(section: EstimateSection) {
    setDeleteSectionDialog({
      isOpen: true,
      section,
    });
  }

  function closeSectionDialog() {
    setEstimateSectionDialog({
      isOpen: false,
      section: undefined,
      estimate: undefined,
    });
  }

  function closeDeleteSectionDialog() {
    setDeleteSectionDialog({
      isOpen: false,
      section: undefined,
    });
  }

  function confirmDeleteSection() {
    if (!deleteSectionDialog.section) {
      return;
    }
    EstimateSectionModel.delete(deleteSectionDialog.section.id).then(() => {
      const sectionIndex = estimateSections.findIndex((section) => {
        return section.id === deleteSectionDialog.section?.id;
      });
      const newEstimateSections = [...estimateSections];
      newEstimateSections.splice(sectionIndex, 1);
      setEstimateSections(newEstimateSections);
      closeDeleteSectionDialog();
    });
  }

  const reorder = (initArr: any[], startIndex: number, endIndex: number) => {
    const result = initArr;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function handleSectionDragEnd(result: any) {
    if (!result.destination) return;
    setEstimateSections((prev) => reorder(prev, result.source.index, result.destination.index));
    EstimateSectionModel.detailAction(result.draggableId, 'move', 'post', { to: result.destination.index }).then(
      (res) => setEstimateSections(res.data)
    );
  }

  function handleItemDragEnd(result: any) {
    if (!result.destination) return;
    setEstimateSections((prev) =>
      prev.map((section) =>
        section.id === result.source.droppableId
          ? { ...section, items: reorder(section.items, result.source.index, result.destination.index) }
          : section
      )
    );
    EstimateItemModel.detailAction(result.draggableId, 'move', 'post', { to: result.destination.index }).then((res) => {
      EstimateSectionModel.all({ estimate: estimateId }).then((sections) => setEstimateSections(sections));
    });
  }

  function renderSections() {
    return estimateSections.map((section, sectionIndex) => (
      <Draggable key={section.id} draggableId={section.id} index={sectionIndex}>
        {(provided) => (
          <div className="EstimateDetail__section" ref={provided.innerRef} {...provided.draggableProps}>
            <div className="EstimateDetail__section__header">
              <h2 className="EstimateDetail__section__header__h2" {...provided.dragHandleProps}>
                {section.name} - {sectionHoursHigh(section).toFixed(2)} Hours
              </h2>
              {user?.is_superuser && (
                <>
                  <IconButton
                    onClick={() => {
                      clickUpdateSection(section);
                    }}
                  >
                    <span className="material-icons">edit</span>
                  </IconButton>
                  <IconButton
                    color="red"
                    onClick={() => {
                      clickDeleteSection(section);
                    }}
                  >
                    <span className="material-icons">delete</span>
                  </IconButton>
                  <Button
                    iconLeading="add"
                    onClick={() => {
                      clickCreateItem(section);
                    }}
                    variant="raised"
                  >
                    Create Item
                  </Button>
                </>
              )}
            </div>
            {!!section.items.length && (
              <div className="EstimateDetail__items">
                <div className="EstimateDetail__items__header">
                  <div className="EstimateDetail__items__header__description">Description</div>
                  <div className="EstimateDetail__items__header__hours">Hours</div>
                  <div className="EstimateDetail__items__header__notes">Notes</div>
                  <div className="EstimateDetail__items__header__questions">Questions</div>
                  <div className="EstimateDetail__items__header__comments">Comments</div>
                </div>
                <DragDropContext onDragEnd={handleItemDragEnd}>
                  <Droppable droppableId={section.id}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {section.items.map((item, itemIndex) => (
                          <Draggable key={item.id} draggableId={item.id} index={itemIndex}>
                            {(provided) => (
                              <div
                                className="EstimateDetail__item__outline"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => {
                                  clickUpdateItem(section, item);
                                }}
                              >
                                <div className="EstimateDetail__item">
                                  <div className="EstimateDetail__item__description">{item.description}</div>
                                  <div className="EstimateDetail__item__hours">
                                    {parseFloat(item.hours_high).toFixed(2)}
                                  </div>
                                  <div className="EstimateDetail__item__notes">
                                    {item.notes && item.notes.length > 0 ? item.notes : ''}
                                  </div>
                                  <div className="EstimateDetail__item__questions">
                                    {item.questions && item.questions.length > 0 ? item.questions : ''}
                                  </div>
                                  <div className="EstimateDetail__item__comments">
                                    {item.comment_count > 0 && item.comment_count}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
          </div>
        )}
      </Draggable>
    ));
  }

  function renderTotal() {
    return (
      <Card>
        <div className="EstimateDetail__total">
          <div className="EstimateDetail__total__field">
            <div className="EstimateDetail__total__label">Hours</div>
            <div className="EstimateDetail__total__value">{totalHoursHigh().toFixed(2)}</div>
          </div>
          <div className="EstimateDetail__total__field">
            <div className="EstimateDetail__total__label">Safe modifier</div>
            <div className="EstimateDetail__total__value">{parseFloat(estimate?.safe_modifier || '1')}</div>
          </div>
          <div className="EstimateDetail__total__field">
            <div className="EstimateDetail__total__label">Hours (Safe)</div>
            <div className="EstimateDetail__total__value">
              {(totalHoursHigh() * parseFloat(estimate?.safe_modifier || '1')).toFixed(2)}
            </div>
          </div>
        </div>
      </Card>
    );
  }

  if (!estimate) {
    return (
      <Container>
        <Spinner message="Loading estimate..." />
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{estimate.name} | Start Studio Portal</title>
      </Helmet>
      <Container>
        <div className="EstimateDetail">
          <h1>{estimate.name}</h1>
          <p>{estimate.description}</p>
          <DragDropContext onDragEnd={handleSectionDragEnd}>
            <Droppable droppableId={estimate.id}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {renderSections()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {user?.is_superuser && (
            <Button
              onClick={() => {
                clickCreateSection();
              }}
            >
              Create Section
            </Button>
          )}
          <h2>Total</h2>
          {renderTotal()}
        </div>
      </Container>
      <EstimateItemDrawer
        isOpen={estimateItemDrawer.isOpen}
        onClose={() => {
          closeItemDrawer();
        }}
        style={{
          width: '40rem',
        }}
        item={estimateItemDrawer.item}
        section={estimateItemDrawer.section}
        onCreateItem={(item) => {
          const sectionIndex = estimateSections.findIndex((section) => {
            return section.id === item.section;
          });
          const newEstimateSections = [...estimateSections];
          newEstimateSections[sectionIndex].items.push(item);
          setEstimateSections(newEstimateSections);
          closeItemDrawer();
        }}
        onUpdateItem={(item) => {
          const sectionIndex = estimateSections.findIndex((section) => {
            return section.id === item.section;
          });
          const itemIndex = estimateSections[sectionIndex].items.findIndex((i) => {
            return i.id === item.id;
          });
          const newEstimateSections = [...estimateSections];
          newEstimateSections[sectionIndex].items[itemIndex] = item;
          setEstimateSections(newEstimateSections);
          closeItemDrawer();
        }}
        onDeleteItem={(item) => {
          setEstimateSections((prev) =>
            prev.map((section) =>
              section.id === item.section
                ? { ...section, items: section.items.filter((i) => i.id !== item.id) }
                : section
            )
          );
        }}
      />
      <EstimateSectionDialog
        isOpen={estimateSectionDialog.isOpen}
        onClose={() => {
          closeSectionDialog();
        }}
        estimate={estimate}
        section={estimateSectionDialog.section}
        onCreateSection={(section) => {
          setEstimateSections([...estimateSections, section]);
          closeSectionDialog();
        }}
        onUpdateSection={(section) => {
          const sectionIndex = estimateSections.findIndex((s) => {
            return s.id === section.id;
          });
          const newEstimateSections = [...estimateSections];
          newEstimateSections[sectionIndex] = section;
          setEstimateSections(newEstimateSections);
          closeSectionDialog();
        }}
      />
      <ConfirmDialog
        confirmLabel="Delete"
        danger
        isOpen={deleteSectionDialog.isOpen}
        message="Are you sure you want to delete this section?"
        onClose={() => {
          closeDeleteSectionDialog();
        }}
        onConfirm={() => {
          confirmDeleteSection();
        }}
        title="Delete Section"
      />
    </>
  );
};
