import { ChevronDownIcon, ChevronUpIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ConfirmDialog } from '~/src/components';
import { Button, Input, Textarea } from '~/src/ui';
import { useMilestoneState } from '../../contexts';
import { TestFlow, TestStep } from '../../types';
import { DraggableTestStep } from '../DraggableTestStep/DraggableTestStep';
import './DraggableTestFlow.scss';

type DraggableTestFlowProps = {
  flow: TestFlow;
  index: number;
  collapsed?: boolean;
};

export const DraggableTestFlow = (props: DraggableTestFlowProps) => {
  const [testSteps, setTestSteps] = React.useState<TestStep[]>([]);
  const [editing, setEditing] = React.useState<{ isEditing: boolean; description: string }>({
    isEditing: false,
    description: props.flow.name,
  });
  const [adding, setAdding] = React.useState<{ isAdding: boolean; description: string }>({
    isAdding: false,
    description: '',
  });
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(true);

  const { testFlows, moveTestFlow, updateTestFlow, deleteTestFlow, addTestStep, moveTestStep } = useMilestoneState();

  React.useEffect(() => {
    const flow = testFlows.find((f) => f.id === props.flow.id);
    flow && setTestSteps(flow.test_steps);
  }, [testFlows]);

  React.useEffect(() => {
    setEditing((p) => ({ ...p, description: props.flow.name }));
  }, [props.flow.name]);

  React.useEffect(() => {
    setIsExpanded(!props.collapsed);
  }, [props.collapsed]);

  const reorder = (initArr: any[], startIndex: number, endIndex: number) => {
    const result = initArr;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function handleDragEnd(result: any) {
    if (!result.destination) return;
    setTestSteps((prev) => reorder(prev, result.source.index, result.destination.index));
    moveTestStep(result.draggableId, { to: result.destination.index });
  }

  function newStep() {
    return (
      <div className="DraggableTestStep">
        <div className="DraggableTestStep__body DraggableTestStep__body--addStep">
          <span className="DraggableTestStep__body__text">
            <Textarea
              fluid
              autoFocus
              className="DraggableTestStep__body__text__textarea"
              rows={5}
              value={adding.description}
              onChange={(e) => setAdding((p) => ({ ...p, description: e.target.value }))}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  if (adding.description) {
                    addTestStep(props.flow.id, { description: adding.description, type: 'VERIFY' });
                  }
                  setAdding({ isAdding: false, description: '' });
                }
                if (e.key === 'Escape') setAdding({ isAdding: false, description: '' });
              }}
              onBlur={() => setAdding({ isAdding: false, description: '' })}
            />
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      <Draggable draggableId={props.flow.id} index={props.index}>
        {(provided) => (
          <>
            <div className="DraggableTestFlow" ref={provided.innerRef} {...provided.draggableProps}>
              <div className="DraggableTestFlow__container" {...provided.dragHandleProps}>
                {!editing.isEditing ? (
                  <>
                    <div
                      className="DraggableTestFlow__container__content"
                      onClick={() => setEditing((p) => ({ ...p, isEditing: true }))}
                      style={{ cursor: 'text' }}
                    >
                      <h3>{props.flow.name}</h3>
                      {!isExpanded ? (
                        <PlusIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsExpanded(true);
                          }}
                        />
                      ) : (
                        <MinusIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsExpanded(false);
                          }}
                        />
                      )}
                    </div>
                    <span className="DraggableTestFlow__container__buttons">
                      <Button
                        className="DraggableTestFlow__container__buttons__editButton"
                        color="blue"
                        onClick={() => setEditing((p) => ({ ...p, isEditing: true }))}
                      >
                        Edit
                      </Button>
                      <Button
                        className="DraggableTestFlow__container__buttons__deleteButton"
                        color="red"
                        onClick={() => setIsDeleting(true)}
                      >
                        Delete
                      </Button>
                      <ChevronUpIcon onClick={() => moveTestFlow(props.flow.id, { direction: 'up' })} />
                      <ChevronDownIcon onClick={() => moveTestFlow(props.flow.id, { direction: 'down' })} />
                    </span>
                  </>
                ) : (
                  <div className="DraggableTestFlow__container__content DraggableTestFlow__container__content--editing">
                    <Input
                      fluid
                      autoFocus
                      type="text"
                      placeholder="Enter flow description"
                      value={editing.description}
                      onChange={(e) => setEditing((p) => ({ ...p, description: e.target.value }))}
                      onBlur={() => {
                        updateTestFlow(props.flow.id, editing.description);
                        setEditing({ isEditing: false, description: props.flow.name });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          updateTestFlow(props.flow.id, editing.description);
                          setEditing({ isEditing: false, description: props.flow.name });
                        }
                        if (e.key === 'Escape') setEditing({ isEditing: false, description: props.flow.name });
                      }}
                    />
                  </div>
                )}
              </div>
              {isExpanded && (
                <>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId={props.flow.id}>
                      {(provided) => (
                        <div className="DraggableTestFlow__steps" ref={provided.innerRef} {...provided.droppableProps}>
                          {testSteps
                            ?.filter((step) => step.test_flow === props.flow.id)
                            .map((step, index: number) => (
                              <DraggableTestStep key={step.id} step={step} index={index} />
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {adding.isAdding && newStep()}
                  {!adding.isAdding && (
                    <Button
                      color="blue"
                      onClick={() => setAdding((p) => ({ ...p, isAdding: true }))}
                      style={{ margin: '0.5rem 0 0 2rem' }}
                    >
                      Add step
                    </Button>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </Draggable>
      <ConfirmDialog
        confirmLabel="Delete"
        danger
        isOpen={isDeleting}
        message={`Are you sure you want to delete this flow? This action will result in the deletion of ${testSteps.length} steps`}
        onClose={() => setIsDeleting(false)}
        onConfirm={() => deleteTestFlow(props.flow.id)}
        title="Delete Test Flow"
      />
    </>
  );
};
