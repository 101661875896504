import { EyeIcon } from '@heroicons/react/24/outline';
import * as React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useParams, useSearchParams } from 'react-router-dom';
import { Button, Container, Input, Popover, Spinner } from '~/src/ui';
import { DraggableTestFlow } from '../../components';
import { MilestoneProvider, useMilestoneState } from '../../contexts/MilestoneContext';
import { TestFlow } from '../../types';
import './MilestoneDetails.scss';

export const MilestoneDetails = () => {
  const { milestoneId } = useParams();
  if (!milestoneId) return null;
  return (
    <MilestoneProvider id={milestoneId}>
      <Milestone />
    </MilestoneProvider>
  );
};

const useActiveObserver = (flowIds: any[]) => {
  const observers = React.useRef<Map<String, IntersectionObserver>>(new Map());
  const flowsRef = React.useRef<Map<string, HTMLDivElement>>(new Map());
  const [activeFlowIds, setActiveFlowIds] = React.useState<string[]>([]);

  function scrollToFlow(flowId: string) {
    const node = flowsRef.current.get(flowId);
    node && node.scrollIntoView({ behavior: 'smooth' });
  }

  const refCallback = React.useCallback(
    (node: HTMLDivElement | null, id: string) => {
      node ? flowsRef.current.set(id, node) : flowsRef.current.delete(id);

      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && !activeFlowIds.includes(id)) {
            setActiveFlowIds((prev) => flowIds.filter((f) => prev.includes(f) || f === id));
          } else if (!entry.isIntersecting && activeFlowIds.includes(id)) {
            setActiveFlowIds((prev) => flowIds.filter((f) => prev.includes(f) && f !== id));
          }
        },
        { threshold: 0 }
      );

      observers.current?.get(id)?.disconnect();
      node && observer.observe(node);
      observers.current.set(id, observer);
    },
    [activeFlowIds]
  );

  return { activeFlowIds, refCallback, scrollToFlow };
};

const Milestone = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageBottomRef = React.useRef<HTMLDivElement>(null);
  const [testFlows, setTestFlows] = React.useState<TestFlow[]>([]);
  const [scrolling, setScrolling] = React.useState(false);
  const [toc, setToc] = React.useState<{
    isVisible: boolean;
    flowId: string;
    isAdding: boolean;
    addingDescription: string;
  }>({
    isVisible: true,
    flowId: '',
    isAdding: false,
    addingDescription: '',
  });
  const [adding, setAdding] = React.useState<{ isAdding: boolean; description: string }>({
    isAdding: false,
    description: '',
  });
  const [collapseView, setCollapseView] = React.useState<{ popoverOpen: boolean; collapsed: boolean }>({
    popoverOpen: false,
    collapsed: false,
  });
  const { loadData, milestone, testFlows: contextTestFlows, addTestFlow, moveTestFlow } = useMilestoneState();
  const { activeFlowIds, refCallback, scrollToFlow } = useActiveObserver(testFlows.map((f) => f.id));

  React.useEffect(() => {
    loadData();

    const setScroll = () => {
      setScrolling(true);
      setSearchParams(undefined);
    };
    window.addEventListener('wheel', setScroll);

    setTimeout(() => {
      const flow = searchParams.get('flow');
      if (flow) {
        scrollToFlow(flow);
        setToc((p) => ({ ...p, flowId: flow }));
      }
    }, 100);

    return () => {
      window.removeEventListener('wheel', setScroll);
    };
  }, []);

  React.useEffect(() => {
    const newFlows = contextTestFlows.sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0));
    setTestFlows(newFlows);
  }, [contextTestFlows]);

  React.useEffect(() => {
    if (!scrolling) return;
    setToc((p) => ({ ...p, flowId: activeFlowIds[0] }));
  }, [activeFlowIds, scrolling]);

  const reorder = (initArr: any[], startIndex: number, endIndex: number) => {
    const result = initArr;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function handleDragEnd(result: any) {
    if (!result.destination) return;
    setTestFlows((prev) => reorder(prev, result.source.index, result.destination.index));
    moveTestFlow(result.draggableId, { to: result.destination.index });
  }

  function newFlow() {
    return (
      <div className="DraggableTestFlow">
        <div className="DraggableTestFlow__container">
          <div className="DraggableTestFlow__container__content DraggableTestFlow__container__content--editing">
            <Input
              fluid
              autoFocus
              type="text"
              placeholder="New flow description"
              value={adding.description}
              onChange={(e) => setAdding((p) => ({ ...p, description: e.target.value }))}
              onBlur={() => setAdding((p) => ({ description: '', isAdding: false }))}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && adding.description) {
                  milestone && addTestFlow(milestone.id, adding.description);
                  setAdding({ isAdding: false, description: '' });
                }
                if (e.key === 'Escape') setAdding({ isAdding: false, description: '' });
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  if (!milestone) {
    return (
      <Container>
        <Spinner message="Loading milestone..." />
      </Container>
    );
  }

  return (
    <div className="MilestoneDetails">
      <div className="MilestoneDetails__title">
        {!toc.isVisible && (
          <a className="MilestoneDetails__title__tocLink" onClick={() => setToc((p) => ({ ...p, isVisible: true }))}>
            [Table of Contents]
          </a>
        )}
        <h1 className="MilestoneDetails__title__h1">{`${milestone?.name} Test Plan`}</h1>
        <div className="MilestoneDetails__title__svgContainer">
          <EyeIcon onClick={() => setCollapseView((p) => ({ ...p, popoverOpen: true }))} />
          <Popover
            isOpen={collapseView.popoverOpen}
            onClose={() => setCollapseView((p) => ({ ...p, popoverOpen: false }))}
            style={{ right: 0, top: '100%' }}
          >
            <div className="MilestoneDetails__title__svgContainer__popover">
              <a onClick={() => setCollapseView({ popoverOpen: false, collapsed: true })}>Collapse All</a>
              <a onClick={() => setCollapseView({ popoverOpen: false, collapsed: false })}>Expand All</a>
            </div>
          </Popover>
        </div>
      </div>
      <div className="MilestoneDetails__body">
        {toc.isVisible && (
          <div className="MilestoneDetails__body__toc">
            <div className="MilestoneDetails__body__toc__fixed">
              <p className="MilestoneDetails__body__toc__title">
                Table of Contents [<a onClick={() => setToc((p) => ({ ...p, isVisible: false }))}>hide</a>]
              </p>
              {testFlows.map((flow) => (
                <a
                  key={flow.id}
                  onClick={() => {
                    setSearchParams({ flow: flow.id });
                    setScrolling(false);
                    scrollToFlow(flow.id);
                    setToc((p) => ({ ...p, flowId: flow.id }));
                  }}
                  style={{ fontWeight: toc.flowId === flow.id ? 'bold' : 'normal' }}
                >
                  {flow.name}
                </a>
              ))}
              {toc.isAdding ? (
                <Input
                  fluid
                  autoFocus
                  type="text"
                  value={toc.addingDescription}
                  onChange={(e) => setToc((p) => ({ ...p, addingDescription: e.target.value }))}
                  onBlur={() => setToc((p) => ({ ...p, isAdding: false, addingDescription: '' }))}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && toc.addingDescription) {
                      milestone &&
                        addTestFlow(milestone.id, toc.addingDescription, (res) => {
                          pageBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
                        });
                      setToc((p) => ({ ...p, isAdding: false, addingDescription: '' }));
                    }
                    if (e.key === 'Escape') setToc((p) => ({ ...p, isAdding: false, addingDescription: '' }));
                  }}
                />
              ) : (
                <a onClick={() => setToc((p) => ({ ...p, isAdding: true }))}>+ Add flow</a>
              )}
            </div>
          </div>
        )}
        <div className="MilestoneDetails__body__flows">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={milestone.id}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {testFlows?.map((flow, index: number) => (
                    <div key={flow.id} ref={(node) => refCallback(node, flow.id)}>
                      <DraggableTestFlow flow={flow} index={index} collapsed={collapseView.collapsed} />
                    </div>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {adding.isAdding ? (
            newFlow()
          ) : (
            <Button
              className="MilestoneDetails__body__addFlow"
              size="lg"
              color="blue"
              onClick={() => setAdding((p) => ({ ...p, isAdding: true }))}
            >
              Add flow
            </Button>
          )}
          <div ref={pageBottomRef}></div>
        </div>
      </div>
    </div>
  );
};
