import * as classNames from 'classnames';
import * as React from 'react';
import './Radio.scss';

type RadioProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  testId?: string;
};

export const Radio = React.forwardRef(
  ({ className, label, testId, ...rest }: RadioProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    let containerClass = 'Radio__container';

    function getClassNames() {
      return classNames('Radio', className);
    }

    return (
      <>
        <label className={containerClass} data-testid={testId && `${testId}-container`}>
          {label}
          <input className={getClassNames()} data-testid={testId} type="radio" ref={ref} {...rest} />
          <span className="Radio__mark"></span>
        </label>
      </>
    );
  }
);
