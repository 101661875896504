import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container } from '~/src/ui';
import { ProjectModel } from '../../api';
import { Project } from '../../types';
import './ProjectList.scss';

export const ProjectList = () => {
  const [projects, setProjects] = React.useState<Project[]>([]);

  const getProjects = () => {
    ProjectModel.list().then((projects) => {
      setProjects(projects.data.results);
    });
  };

  React.useEffect(() => {
    getProjects();
  }, []);

  return (
    <>
      <Helmet>
        <title>Projects | Start Studio Portal</title>
      </Helmet>
      <Container>
        <div className="ProjectList">
          <h1>Projects</h1>
          <ul className="ProjectList__projects">
            {projects.map((project) => (
              <li key={project.slug} className="ProjectList__project">
                <Link to={`/projects/${project.slug}`}>
                  {project.client_name} / <b>{project.name}</b>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </>
  );
};
