import * as React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Container } from '~/src/ui';
import { useAuth } from '~/src/features/auth';
import { ClientModel } from '../../api';
import { ClientDrawer } from '../../components';
import { Client } from '../../types';
import './ClientList.scss';

export const ClientList = () => {
  const [clients, setClients] = React.useState<Client[]>([]);
  const [createClientOpen, setCreateClientOpen] = React.useState(false);

  const { user } = useAuth();

  React.useEffect(() => {
    ClientModel.list().then((clients) => {
      setClients(clients.data.results);
    });
  }, []);

  function clickCreateClient() {
    setCreateClientOpen(true);
  }

  function closeCreateClient() {
    setCreateClientOpen(false);
  }

  function onClientCreate(newClient: Client) {
    setClients([...clients, newClient]);
    closeCreateClient();
  }

  return (
    <>
      <Container>
        <div className="ClientList">
          <div className="ClientList__header">
            <h1>Clients</h1>
            {user?.is_superuser && (
              <Button color="primary" iconLeading="add" onClick={clickCreateClient} variant="raised">
                Create Client
              </Button>
            )}
          </div>
          <ul className="ClientList__clients">
            {clients.map((client) => (
              <li key={client.slug} className="ClientList__client">
                <Link to={`/clients/${client.slug}`}>{client.name}</Link>
                {client.is_internal && <Badge>Internal</Badge>}
              </li>
            ))}
          </ul>
        </div>
      </Container>
      <ClientDrawer isOpen={createClientOpen} onClientCreate={onClientCreate} onClose={closeCreateClient} />
    </>
  );
};
