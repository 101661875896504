import * as React from 'react';
import { TagsTextarea } from '../../components';
import './InlineTags.scss';

export const InlineTags = () => {
  return (
    <div className="InlineTags">
      <h1>Inline Tags</h1>
      <TagsTextarea />
      <p style={{ fontStyle: 'italic' }}>Add an identifier to search for or add a tag.</p>
      <p style={{ fontStyle: 'italic' }}>Use '#' for Websites, and '@' for Projects.</p>
    </div>
  );
};
