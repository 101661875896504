import * as React from 'react';
import { IconButton } from '../IconButton/IconButton';
import { useToast } from './ToastContext';
import './Toaster.scss';

export const Toaster = () => {
  const { toasts, clickToast } = useToast();

  if (toasts.length < 1) {
    return null;
  }

  return (
    <div className="Toaster" data-testid="toaster">
      {toasts.map((toast, index) => {
        let toastClass = `Toaster__toast Toaster__toast--${toast.type}`;
        return (
          <div
            className={toastClass}
            key={toast.id}
            onClick={() => {
              clickToast(index);
            }}
          >
            {toast.message}
            <IconButton>
              <span className="material-icons">close</span>
            </IconButton>
          </div>
        );
      })}
    </div>
  );
};
