import * as classNames from 'classnames';
import * as React from 'react';
import './Card.scss';

type CardShadow = 'none' | 'sm' | 'md' | 'lg';

type CardProps = React.HTMLAttributes<HTMLDivElement> & {
  fluid?: boolean;
  interactive?: boolean;
  rounded?: boolean;
  shadow?: CardShadow;
  testId?: string;
};

export const Card = ({
  className,
  fluid,
  interactive = false,
  shadow = 'sm',
  rounded = true,
  testId,
  ...rest
}: CardProps) => {
  function getClassNames() {
    return classNames(
      'Card',
      {
        'Card--fluid': fluid,
        'Card--interactive': interactive,
        'Card--rounded': rounded,
        [`shadow-${shadow}`]: shadow,
      },
      className
    );
  }

  return <div className={getClassNames()} data-testid={testId} {...rest} />;
};
