import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '~/src/assets/logo.svg';
import { useAuth } from '~/src/features/auth';
import { Button, IconButton, Menu, MenuItem, Popover } from '~/src/ui';
import './Nav.scss';

type NavProps = {
  onToggleSideNav: () => void;
};

export const Nav = (props: NavProps) => {
  const [userMenu, setUserMenuOpen] = React.useState(false);

  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    setUser(undefined);
    navigate('/login');
  }

  function renderAuthLinks() {
    return (
      <>
        <div className="flex gap-2">
          <Button navigateTo={'/sign-up'}>Sign Up</Button>
          <Button navigateTo={'/login'}>Login</Button>
        </div>
      </>
    );
  }

  function renderUserMenu() {
    return (
      <div className="relative">
        <div
          className="flex align-center gap-2 cursor-pointer"
          onClick={() => {
            setUserMenuOpen(true);
          }}
        >
          {user && (
            <div className="text-size-s text-weight-semi-bold">
              {user.first_name} {user.last_name}
            </div>
          )}
          <IconButton testId="user-menu-button">
            <span className="material-icons">person</span>
          </IconButton>
        </div>
        <Popover
          isOpen={userMenu}
          onClose={() => {
            setUserMenuOpen(false);
          }}
          style={{ padding: '0', right: '0', top: '40px' }}
          testId="user-menu"
        >
          <Menu style={{ border: 'none' }}>
            <MenuItem
              iconLeading="person"
              onClick={() => {
                setUserMenuOpen(false);
                navigate('/profile');
              }}
            >
              Profile
            </MenuItem>
            <MenuItem
              iconLeading="logout"
              onClick={() => {
                setUserMenuOpen(false);
                logout();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </Popover>
      </div>
    );
  }

  return (
    <div className="Nav">
      <div className="Nav__content">
        {user ? (
          <>
            <IconButton
              onClick={() => {
                props.onToggleSideNav();
              }}
            >
              <span className="material-icons">menu</span>
            </IconButton>
            {/* <Input className="Nav__search" iconLeading="search" placeholder="Search" rounded /> */}
          </>
        ) : (
          <>
            <img className="Nav__logo" src={Logo} alt="StartStudio" />
            <div className="Nav__spacer"></div>
          </>
        )}
        {!user ? renderAuthLinks() : renderUserMenu()}
      </div>
    </div>
  );
};
