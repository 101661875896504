import { addMonths, format, getDay, getDaysInMonth, getMonth, getYear, isSameDay, setDate, subMonths } from 'date-fns';
import * as React from 'react';
import { IconButton } from '../IconButton/IconButton';
import { Input } from '../Input/Input';
import { Popover } from '../Popover/Popover';
import './DatePicker.scss';

type DatePickerProps = {
  fluid?: boolean;
  onChange?: (e: Date | undefined) => void;
  testId?: string;
  value?: Date;
};

export const DatePicker = (props: DatePickerProps) => {
  const now = new Date();
  const initialDate = props.value ? props.value : now;
  const [calendarDate, setCalendarDate] = React.useState(initialDate);
  const [calendarOpen, setCalendarOpen] = React.useState(false);
  const daysInMonth = getDaysInMonth(calendarDate);
  const firstDayWeekday = getDay(setDate(calendarDate, 1));

  React.useEffect(() => {
    if (!props.value) {
      return;
    }
    setCalendarDate(props.value);
  }, [props.value]);

  function back(months = 1) {
    const backDate = subMonths(calendarDate, months);
    setCalendarDate(backDate);
  }

  function forward(months = 1) {
    const forwardDate = addMonths(calendarDate, months);
    setCalendarDate(forwardDate);
  }

  function clickDay(day: number) {
    const newSelected = new Date(getYear(calendarDate), getMonth(calendarDate), day);

    if (!props.onChange) {
      return;
    }

    if (props.value && isSameDay(newSelected, props.value)) {
      props.onChange(undefined);
    } else {
      props.onChange(newSelected);
    }
  }

  function dayIsSelected(day: number) {
    if (!day || !props.value) {
      return false;
    }
    const date = new Date(getYear(calendarDate), getMonth(calendarDate), day);
    return isSameDay(date, props.value);
  }

  function daysInMonthArray() {
    let array = [];
    for (let i = 0; i < daysInMonth; i++) {
      array.push(i + 1);
    }
    return array;
  }

  function sliceIntoChunks(arr: any[], chunkSize: number) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  function weekChunk() {
    let dimArray = daysInMonthArray();
    let firstWeek = []; // e.g: [null, null, null, null, null, 1, 2]
    let lastDayInFirstWeek = 0;
    let rest = [];
    for (let i = 0; i < firstDayWeekday; i++) {
      firstWeek.push(null);
    }
    for (let i = firstDayWeekday; i < 7; i++) {
      firstWeek.push(dimArray[i - firstDayWeekday]);
      lastDayInFirstWeek = i - firstDayWeekday;
    }
    for (let i = lastDayInFirstWeek + 1; i < dimArray.length; i++) {
      rest.push(dimArray[i]);
    }
    return sliceIntoChunks(firstWeek.concat(rest), 7);
  }

  return (
    <>
      <div className="DatePicker__container" data-testid={props.testId && `${props.testId}-container`}>
        <Input
          fluid
          iconLeading="calendar_month"
          onClick={() => {
            setCalendarOpen(!calendarOpen);
          }}
          readOnly
          style={{
            cursor: 'pointer',
          }}
          testId={props.testId && `${props.testId}-input`}
          value={props.value ? format(props.value, 'MMMM d, yyyy') : ''}
        />
        <Popover
          isOpen={calendarOpen}
          onClose={() => {
            setCalendarOpen(false);
          }}
          style={{
            padding: '1rem',
            width: '320px',
          }}
          testId={props.testId && `${props.testId}-popover`}
        >
          <div className="DatePicker">
            <div className="DatePicker__navigation">
              <IconButton
                onClick={() => {
                  back(12);
                }}
                testId={props.testId && `${props.testId}-back-year`}
              >
                <span className="material-icons">keyboard_double_arrow_left</span>
              </IconButton>
              <IconButton
                onClick={() => {
                  back();
                }}
                testId={props.testId && `${props.testId}-back`}
              >
                <span className="material-icons">keyboard_arrow_left</span>
              </IconButton>
              <h4 className="DatePicker__month" data-testid={props.testId && `${props.testId}-month`}>
                {format(calendarDate, 'MMM yyyy')}
              </h4>
              <IconButton
                onClick={() => {
                  forward();
                }}
                testId={props.testId && `${props.testId}-forward`}
              >
                <span className="material-icons">keyboard_arrow_right</span>
              </IconButton>
              <IconButton
                onClick={() => {
                  forward(12);
                }}
                testId={props.testId && `${props.testId}-forward-year`}
              >
                <span className="material-icons">keyboard_double_arrow_right</span>
              </IconButton>
            </div>
            <div className="DatePicker__calendar" data-testid={props.testId && `${props.testId}-calendar`}>
              <table>
                <thead>
                  <tr>
                    <th>S</th>
                    <th>M</th>
                    <th>T</th>
                    <th>W</th>
                    <th>T</th>
                    <th>F</th>
                    <th>S</th>
                  </tr>
                </thead>
                <tbody>
                  {weekChunk().map((week, weekIndex) => {
                    return (
                      <tr key={weekIndex}>
                        {week.map((day, dayIndex) => {
                          return (
                            <td
                              key={dayIndex}
                              className={dayIsSelected(day) ? 'DatePicker__selected' : undefined}
                              onClick={() => {
                                clickDay(day);
                              }}
                            >
                              {day}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Popover>
      </div>
    </>
  );
};
