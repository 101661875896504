import * as classNames from 'classnames';
import * as React from 'react';
import { Button, ButtonProps } from '../Button/Button';
import './MenuItem.scss';

type MenuItemProps = ButtonProps & {};

export const MenuItem = ({
  className,
  style = {
    textAlign: 'left',
  },
  ...rest
}: MenuItemProps) => {
  function getClassNames() {
    return classNames(className);
  }

  return (
    <>
      <Button
        className={getClassNames()}
        style={{
          borderRadius: '0',
          ...style,
        }}
        {...rest}
      />
    </>
  );
};
