import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card, Container } from '~/src/ui';
import { ForgotPasswordForm } from '../../components';

export const ForgotPassword = () => {
  return (
    <>
      <Helmet>
        <title>Forgot Password | Start Studio Portal</title>
      </Helmet>
      <Container>
        <div className="centerPage">
          <Card>
            <h1 className="text-center">Forgot Password?</h1>
            <ForgotPasswordForm />
            <Link className="block mt-8 text-center" to="/login">
              Back to Login
            </Link>
          </Card>
        </div>
      </Container>
    </>
  );
};
