import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Button, Card, Container } from '~/src/ui';
import { BasicInfoForm, RevokeTokenForm } from '../../components';

export const Profile = () => {
  const [revokeTokenOpen, setRevokeTokenOpen] = React.useState(false);

  return (
    <>
      <Helmet>
        <title>Profile | Start Studio Portal</title>
      </Helmet>
      <Container>
        <h1>Profile</h1>
        <Card fluid>
          <h3>Basic Information</h3>
          <BasicInfoForm />
        </Card>
        <Card fluid>
          <h3>Sign out everywhere</h3>
          <p>Sign out on all devices, including the web, mobile, desktop or any other devices.</p>
          <Button
            color="red"
            onClick={() => {
              setRevokeTokenOpen(true);
            }}
            variant="outlined"
          >
            Sign out everywhere
          </Button>
        </Card>
      </Container>
      <RevokeTokenForm isOpen={revokeTokenOpen} onClose={() => setRevokeTokenOpen(false)} />
    </>
  );
};
