import * as React from 'react';

type useCountdownProps = {
  onComplete: () => void;
  seconds: number;
};

export const useCountdown = (props: useCountdownProps) => {
  const [remaining, setRemaining] = React.useState(props.seconds);

  React.useEffect(() => {
    if (remaining === 0) {
      props.onComplete();
      return;
    }
    setTimeout(() => {
      setRemaining(remaining - 1);
    }, 1000);
  }, [remaining]);

  return {
    remaining,
  };
};
