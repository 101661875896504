import { format } from 'date-fns';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, DatePicker, Input, useToast } from '~/src/ui';
import { parseDateString } from '~/src/utils/format';
import { UserModel } from '../../api';
import { useAuth } from '../../contexts';
import { ChangePasswordForm } from '../ChangePasswordForm/ChangePasswordForm';
import './BasicInfoForm.scss';

type BasicInfoFormData = {
  first_name: string;
  last_name: string;
  birthdate: Date | undefined;
  phone: string;
};

const defaultFormData: BasicInfoFormData = {
  first_name: '',
  last_name: '',
  birthdate: undefined,
  phone: '',
};

export const BasicInfoForm = () => {
  const [changePasswordOpen, setChangePasswordOpen] = React.useState(false);

  const { user, setUser } = useAuth();
  const { control, formState, handleSubmit, register, setValue } = useForm<BasicInfoFormData>({
    defaultValues: defaultFormData,
  });
  const { success } = useToast();

  React.useEffect(() => {
    if (!user) {
      return;
    }

    setValue('first_name', user.first_name);
    setValue('last_name', user.last_name);
    setValue('birthdate', user.birthdate ? parseDateString(user.birthdate) : undefined);
    setValue('phone', user.phone);
  }, [user]);

  const onSubmit = handleSubmit((data) => {
    if (!user) {
      return;
    }

    const { first_name, last_name, birthdate, phone } = data;
    UserModel.update(user.id, {
      first_name,
      last_name,
      birthdate: birthdate ? format(birthdate, 'yyyy-MM-dd') : null,
      phone,
    })
      .then((res) => {
        setUser(res.data);
        success('Your profile has been updated');
      })
      .catch(() => {});
  });

  return (
    <>
      <form className="BasicInfoForm" onSubmit={onSubmit}>
        <div className="BasicInfoForm__row">
          <div className="BasicInfoForm__field">
            <label>Email</label>
            <Input fluid readOnly value={user?.email} />
          </div>
        </div>
        <div className="BasicInfoForm__row">
          <div className="BasicInfoForm__field">
            <label>First Name</label>
            <Input fluid {...register('first_name')} />
          </div>
          <div className="BasicInfoForm__field">
            <label>Last Name</label>
            <Input fluid {...register('last_name')} />
          </div>
        </div>
        <div className="BasicInfoForm__row">
          <div className="BasicInfoForm__field">
            <label>Birthdate</label>
            <Controller
              control={control}
              name="birthdate"
              render={({ field }) => (
                <DatePicker
                  fluid
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  value={field.value}
                />
              )}
            />
          </div>
        </div>
        <div className="BasicInfoForm__row">
          <div className="BasicInfoForm__field">
            <label>Phone</label>
            <Input fluid {...register('phone')} />
          </div>
        </div>
        <div className="BasicInfoForm__row">
          <div className="BasicInfoForm__field">
            <label>Password</label>
            <Button
              onClick={() => {
                setChangePasswordOpen(true);
              }}
              variant="outlined"
            >
              Change Password
            </Button>
          </div>
        </div>
        <div className="BasicInfoForm__actions">
          <Button color="primary" disabled={!formState.isValid} type="submit" variant="raised">
            Save
          </Button>
        </div>
      </form>
      <ChangePasswordForm
        isOpen={changePasswordOpen}
        onClose={() => {
          setChangePasswordOpen(false);
        }}
      />
    </>
  );
};
