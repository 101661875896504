import * as React from 'react';
import * as Rf from 'reactflow';
import 'reactflow/dist/style.css';
import './DbModeling.scss';

const initialNodes = [
  {
    id: '1',
    type: 'model',
    data: {
      label: 'Clients',
      attributes: [
        { label: 'Name' },
        { label: 'Projects', sourceHandle: true },
        { label: 'Milestones', sourceHandle: true },
        { label: 'Slack' },
        { label: 'LinkedIn' },
        { label: 'Github' },
        { label: 'GitLab' },
      ],
    },
    position: { x: 0, y: 0 },
  },
  {
    id: '2',
    type: 'model',
    data: {
      label: 'Estimates',
      attributes: [
        { label: 'Name' },
        { label: 'Milestone', targetHandle: true },
        { label: 'Estimate sections', sourceHandle: true },
      ],
    },
    position: { x: 150, y: 0 },
  },
  {
    id: '3',
    type: 'model',
    data: {
      label: 'Estimate Section',
      attributes: [
        { label: 'Name' },
        { label: 'Estimate', targetHandle: true },
        { label: 'Estimate items', sourceHandle: true },
      ],
    },
    position: { x: 300, y: 0 },
  },
  {
    id: '4',
    type: 'model',
    data: {
      label: 'Estimate Item',
      attributes: [{ label: 'Name' }, { label: 'Estimate Section', targetHandle: true }],
    },
    position: { x: 550, y: 0 },
  },
  {
    id: '5',
    type: 'model',
    data: {
      label: 'Project',
      attributes: [
        { label: 'Name' },
        { label: 'Client', targetHandle: true },
        { label: 'Milestones', sourceHandle: true },
      ],
    },
    position: { x: 150, y: -150 },
  },
  {
    id: '6',
    type: 'model',
    data: {
      label: 'Milestone',
      attributes: [
        { label: 'Name' },
        { label: 'Project', targetHandle: true },
        { label: 'Test Flows', sourceHandle: true },
      ],
    },
    position: { x: 300, y: -150 },
  },
  {
    id: '7',
    type: 'model',
    data: {
      label: 'Test Flow',
      attributes: [
        { label: 'Name' },
        { label: 'Milestone', targetHandle: true },
        { label: 'Test Steps', sourceHandle: true },
      ],
    },
    position: { x: 450, y: -150 },
  },
  {
    id: '8',
    type: 'model',
    data: {
      label: 'Test Step',
      attributes: [{ label: 'Name' }, { label: 'Test Flow', targetHandle: true }],
    },
    position: { x: 600, y: -150 },
  },
];

export const DbModeling = () => {
  const [nodes, setNodes, onNodesChange] = Rf.useNodesState<any>(initialNodes);
  const [edges, setEdges, onEdgesChange] = Rf.useEdgesState<any>([]);

  const onConnect = React.useCallback(
    (connection: any) => {
      console.log(connection);
      setEdges((eds) => Rf.addEdge(connection, eds));
    },
    [setEdges]
  );

  const nodeTypes = React.useMemo<any>(() => ({ model: ModelNode }), []);

  return (
    <div className="Flow">
      <Rf.ReactFlow
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
      >
        <Rf.Background color="#ccc" variant={Rf.BackgroundVariant.Dots} />
        <Rf.MiniMap nodeStrokeWidth={3} zoomable pannable />
        <Rf.Controls />
      </Rf.ReactFlow>
    </div>
  );
};

type ModelNodeProps = {
  data: {
    label: string;
    attributes: {
      label: string;
      sourceHandle?: boolean;
      targetHandle?: boolean;
    }[];
  };
};

const ModelNode = (props: ModelNodeProps) => {
  return (
    <div className="ModelNode">
      <div className="ModelNode__title">
        <p>{props.data.label}</p>
      </div>
      {props.data.attributes?.map((att, index) => (
        <span className="ModelNode__attribute" key={index}>
          {att.targetHandle && (
            <Rf.Handle
              type={'target'}
              position={Rf.Position.Left}
              id={`${props.data.label}__et${index}`}
              style={{ top: `${52 + index * 24}px` }}
            />
          )}
          <p>{att.label}</p>
          {att.sourceHandle && (
            <Rf.Handle
              type={'source'}
              position={Rf.Position.Right}
              id={`${props.data.label}__es${index}`}
              style={{ top: `${52 + index * 24}px` }}
            />
          )}
        </span>
      ))}
    </div>
  );
};
