import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { Project, ProjectDrawer, ProjectModel } from '~/src/features/projects';
import { Button, Container, Spinner } from '~/src/ui';
import { ClientModel } from '../../api';
import { ClientDrawer } from '../../components';
import { Client } from '../../types';
import './ClientDetail.scss';

export const ClientDetail = () => {
  const [client, setClient] = React.useState<Client>();
  const [projects, setProjects] = React.useState<Project[]>([]);
  const [projectDrawer, setProjectDrawer] = React.useState(false);
  const [clientDrawer, setClientDrawer] = React.useState(false);

  const { clientSlug } = useParams();

  React.useEffect(() => {
    if (!clientSlug) {
      return;
    }

    ClientModel.get(clientSlug).then((client) => {
      setClient(client.data);

      ProjectModel.list({
        client: clientSlug,
      }).then((projects) => {
        setProjects(projects.data.results);
      });
    });
  }, [clientSlug]);

  if (!client) {
    return (
      <Container>
        <Spinner message="Loading client" />
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{client.name} | Start Studio Portal</title>
      </Helmet>
      <Container>
        <div className="ClientDetail">
          <div className="ClientDetail__header">
            <h1>{client.name}</h1>
            <Button
              iconLeading="edit"
              onClick={() => {
                setClientDrawer(true);
              }}
              variant="outlined"
            >
              Edit
            </Button>
          </div>
          <div className="ClientDetail__section">
            <div className="ClientDetail__section__header">
              <h2>Users</h2>
              <Button color="primary" iconLeading="add" variant="raised">
                Add User
              </Button>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {client.users.map((user) => {
                  return (
                    <tr key={user.id}>
                      <td>{user.email}</td>
                      <td>
                        {user.first_name} {user.last_name}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="ClientDetail__section">
            <div className="ClientDetail__section__header">
              <h2>Projects</h2>
              <Button
                color="primary"
                iconLeading="add"
                onClick={() => {
                  setProjectDrawer(true);
                }}
                variant="raised"
              >
                Create Project
              </Button>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {projects.map((project) => {
                  return (
                    <tr key={project.slug}>
                      <td>
                        <Link to={`/projects/${project.slug}`}>{project.name}</Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
      <ClientDrawer
        client={client}
        isOpen={clientDrawer}
        onClose={() => {
          setClientDrawer(false);
        }}
        onClientUpdate={(client) => {
          setClient(client);
          setClientDrawer(false);
        }}
      />
      <ProjectDrawer
        client={client}
        isOpen={projectDrawer}
        onClose={() => {
          setProjectDrawer(false);
        }}
        onCreateProject={(project) => {
          const newProjectsState = [...projects, project].sort((a, b) => {
            return a.name.localeCompare(b.name);
          });

          setProjects(newProjectsState);
          setProjectDrawer(false);
        }}
      />
    </>
  );
};
