import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Button, Container } from '~/src/ui';
import { EstimateModel } from '../../api';
import { Estimate } from '../../types';

export const EstimateList = () => {
  const [estimates, setEstimates] = React.useState<Estimate[]>([]);

  React.useEffect(() => {
    EstimateModel.list().then((estimates) => {
      setEstimates(estimates.data.results);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Estimates | Start Studio Portal</title>
      </Helmet>
      <Container>
        <div className="ProjectList">
          <h1>Estimates</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {estimates.map((estimate) => (
                <tr key={estimate.id}>
                  <td>{estimate.name}</td>
                  <td className="text-right">
                    <Button navigateTo={`/estimates/${estimate.id}`}>View</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </>
  );
};
