import * as classNames from 'classnames';
import * as React from 'react';
import './Popover.scss';

export type PopoverProps = React.HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  onClose?: () => void;
  testId?: string;
};

export const Popover = ({ className, isOpen, onClose, testId, ...rest }: PopoverProps) => {
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (onClose) {
          onClose();
        }
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  function getClassNames() {
    return classNames('Popover', className);
  }

  return (
    <>
      {isOpen && (
        <>
          <div className={getClassNames()} data-testid={testId} {...rest} />
          <div
            className="Popover__overlay"
            data-testid={testId && `${testId}-overlay`}
            onClick={() => {
              if (onClose) {
                onClose();
              }
            }}
          ></div>
        </>
      )}
    </>
  );
};

export default Popover;
