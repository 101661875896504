import { format } from 'date-fns';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '~/src/features/auth';
import { Button, Card, Drawer, DrawerProps, Input, Textarea } from '~/src/ui';
import { EstimateItemCommentModel, EstimateItemModel } from '../../api';
import { EstimateItem, EstimateItemComment, EstimateSection } from '../../types';
import './EstimateItemDrawer.scss';

export type EstimateItemDrawerProps = DrawerProps & {
  item?: EstimateItem;
  onCreateItem: (item: EstimateItem) => void;
  onUpdateItem: (item: EstimateItem) => void;
  onDeleteItem: (item: EstimateItem) => void;
  section?: EstimateSection;
};

export type EstimateItemForm = {
  description: string;
  hours_low: string;
  hours_high: string;
  notes: string;
  questions: string;
};

export type CreateCommentForm = {
  content: string;
};

export const EstimateItemDrawer = ({
  item,
  onClose,
  onCreateItem,
  onUpdateItem,
  onDeleteItem,
  section,
  ...rest
}: EstimateItemDrawerProps) => {
  const [comments, setComments] = React.useState<EstimateItemComment[]>([]);

  const { user } = useAuth();
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm<EstimateItemForm>();

  const {
    handleSubmit: commentSubmit,
    register: commentRegister,
    reset: commentReset,
  } = useForm<CreateCommentForm>({
    defaultValues: {
      content: '',
    },
  });

  React.useEffect(() => {
    if (!item) {
      reset();
      return;
    }

    setValue('description', item.description);
    setValue('hours_low', item.hours_low);
    setValue('hours_high', item.hours_high);
    setValue('notes', item.notes || '');
    setValue('questions', item.questions || '');

    EstimateItemCommentModel.list({
      estimate_item: item.id,
    }).then((comments) => {
      setComments(comments.data.results);
    });
  }, [item]);

  const createOrUpdate = handleSubmit((data) => {
    if (!item) {
      createItem(data);
    } else {
      updateItem(data);
    }
  });

  const createComment = commentSubmit((data) => {
    if (!item) {
      return;
    }

    EstimateItemCommentModel.create({
      content: data.content,
      item: item.id,
    }).then((comment) => {
      commentReset();
      setComments([...comments, comment.data]);
    });
  });

  function createItem(data: EstimateItemForm) {
    if (!section) {
      return;
    }

    EstimateItemModel.create({
      section: section.id,
      description: data.description,
      hours_low: data.hours_low,
      hours_high: data.hours_high,
      notes: data.notes,
      questions: data.questions,
    })
      .then((newItem) => {
        reset();
        onCreateItem(newItem.data);
      })
      .catch((err) => {
        if (!err.response.data) {
          return;
        }
        Object.keys(err.response.data).forEach((key: string) => {
          setError(key as any, {
            message: err.response.data[key][0],
          });
        });
      });
  }

  function updateItem(data: EstimateItemForm) {
    if (!item) {
      return;
    }

    EstimateItemModel.update(item.id, {
      description: data.description,
      hours_low: data.hours_low,
      hours_high: data.hours_high,
      notes: data.notes,
      questions: data.questions,
    })
      .then((newItem) => {
        reset();
        onUpdateItem(newItem.data);
      })
      .catch((err) => {
        if (!err.response.data) {
          return;
        }
        Object.keys(err.response.data).forEach((key: string) => {
          setError(key as any, {
            message: err.response.data[key][0],
          });
        });
      });
  }

  function deleteItem() {
    if (!item) {
      return;
    }
    EstimateItemModel.delete(item.id).then(() => {
      onDeleteItem(item);
      onClose?.();
    });
  }

  function renderUpdateForm() {
    return (
      <>
        <h3>{item ? 'Update' : 'Create'} Item</h3>
        <form className="EstimateItemDrawer__updateForm" onSubmit={createOrUpdate}>
          <label>Description</label>
          <Input fluid {...register('description')} />
          <div className="EstimateItemDrawer__updateForm__group">
            <div className="EstimateItemDrawer__updateForm__group__item">
              <label>Hours (low)</label>
              <Input fluid {...register('hours_low')} />
            </div>
            <div className="EstimateItemDrawer__updateForm__group__item">
              <label>Hours (high)</label>
              <Input fluid {...register('hours_high')} />
            </div>
          </div>
          {errors.hours_low && <p className="error">{errors.hours_low.message}</p>}
          {errors.hours_high && <p className="error">{errors.hours_high.message}</p>}
          <label>Notes</label>
          <Textarea fluid {...register('notes')}></Textarea>
          <label>Questions</label>
          <Textarea fluid {...register('questions')}></Textarea>
          <div className="EstimateItemDrawer__updateForm__actions">
            <Button color="red" onClick={deleteItem}>
              Delete
            </Button>
            <div className="EstimateItemDrawer__updateForm__actions__spacer"></div>
            <Button
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
              variant="raised"
            >
              Cancel
            </Button>
            <Button color="primary" type="submit" variant="raised">
              Save
            </Button>
          </div>
        </form>
      </>
    );
  }

  function renderComments() {
    return comments.map((comment) => {
      return (
        <Card key={comment.id} className="EstimateItemDrawer__comment" fluid>
          <div className="EstimateItemDrawer__comment__user">{comment.user.email}</div>
          <div className="EstimateItemDrawer__comment__createdAt">
            {format(new Date(comment.created_at), 'MM/dd/yyyy hh:mm a')}
          </div>
          <div className="EstimateItemDrawer__comment__content">{comment.content}</div>
        </Card>
      );
    });
  }

  function renderCommentForm() {
    return (
      <form className="EstimateItemDrawer__commentForm" onSubmit={createComment}>
        <Textarea fluid {...commentRegister('content')}></Textarea>
        <div className="EstimateItemDrawer__commentForm__actions">
          <Button variant="raised" type="submit">
            Comment
          </Button>
        </div>
      </form>
    );
  }

  return (
    <Drawer onClose={onClose} {...rest}>
      <div className="EstimateItemDrawer">
        {user?.is_superuser && <>{renderUpdateForm()}</>}
        {item && (
          <>
            <h3>Comments</h3>
            {renderComments()}
            {renderCommentForm()}
          </>
        )}
      </div>
    </Drawer>
  );
};
