import * as React from 'react';
import { Button, Dialog, DialogProps } from '~/src/ui';
import './ConfirmDialog.scss';

export type ConfirmDialogProps = DialogProps & {
  confirmLabel?: string;
  danger?: boolean;
  message: string;
  onConfirm: () => void;
  title: string;
};

export const ConfirmDialog = ({
  confirmLabel,
  danger = false,
  message,
  onClose,
  onConfirm,
  title,
  ...rest
}: ConfirmDialogProps) => {
  return (
    <Dialog onClose={onClose} {...rest}>
      <div className="ConfirmDialog">
        <h3>{title}</h3>
        <p>{message}</p>
        <div className="ConfirmDialog__actions">
          <Button onClick={onClose} variant="raised">
            Cancel
          </Button>
          <Button color={danger ? 'red' : 'primary'} onClick={onConfirm} variant="raised">
            {confirmLabel ? confirmLabel : 'Confirm'}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
