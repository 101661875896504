import { BeakerIcon, FolderIcon, HomeIcon, UsersIcon } from '@heroicons/react/24/outline';
import * as classNames from 'classnames';
import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '~/src/assets/logo.svg';
import { useAuth } from '~/src/features/auth';
import './SideNav.scss';

type SideNavLink = {
  icon: React.ReactNode;
  label: string;
  superuser_only: boolean;
  to: string;
};

const sideNavLinks: SideNavLink[] = [
  // { icon: <HomeIcon />, label: 'Dashboard', superuser_only: false, to: '/' },
  { icon: <UsersIcon />, label: 'Clients', superuser_only: true, to: '/clients' },
  { icon: <FolderIcon />, label: 'Projects', superuser_only: true, to: '/projects' },
  { icon: <FolderIcon />, label: 'Estimates', superuser_only: false, to: '/estimates' },
  { icon: <BeakerIcon />, label: 'Experimental', superuser_only: true, to: '/experimental' },
];

type SideNavProps = {};

export const SideNav = (props: SideNavProps) => {
  const { user } = useAuth();

  function renderNavLinks() {
    const links = sideNavLinks.filter((link) => !link.superuser_only || (user && user.is_superuser));

    return links.map((link, idx) => (
      <NavLink
        key={idx}
        to={link.to}
        className={({ isActive }) =>
          classNames('SideNav__link', {
            'SideNav__link--active': isActive,
          })
        }
        title={link.label}
      >
        <div className="SideNav__link__iconContainer">{link.icon}</div>
        <div className="SideNav__link__label">{link.label}</div>
      </NavLink>
    ));
  }

  return (
    <div className="SideNav">
      <Link to="/">
        <img className="SideNav__logo" src={Logo} alt="StartStudio" />
      </Link>
      {renderNavLinks()}
    </div>
  );
};
