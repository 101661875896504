import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Dialog, DialogProps, Input } from '~/src/ui';
import { MilestoneModel } from '../../api';
import { Milestone, Project } from '../../types';
import './MilestoneDialog.scss';

export type MilestoneDialogProps = DialogProps & {
  milestone?: Milestone;
  onCreateMilestone?: (milestone: Milestone) => void;
  onUpdateMilestone?: (milestone: Milestone) => void;
  project?: Project;
};

export type MilestoneForm = {
  name: string;
};

export const MilestoneDialog = ({
  isOpen,
  milestone,
  onClose,
  onCreateMilestone,
  onUpdateMilestone,
  project,
  ...rest
}: MilestoneDialogProps) => {
  const { handleSubmit, register, reset } = useForm<MilestoneForm>();

  React.useEffect(() => {
    if (!milestone) {
      reset();
      return;
    }

    reset({
      name: milestone.name,
    });
  }, [isOpen]);

  const createOrUpdate = handleSubmit((data) => {
    if (!milestone) {
      createMilestone(data);
    } else {
      updateMilestone(data);
    }
  });

  function createMilestone(data: MilestoneForm) {
    if (!project) {
      return;
    }

    MilestoneModel.create({
      project: project.id,
      ...data,
    }).then((res) => {
      if (onCreateMilestone) {
        onCreateMilestone(res.data);
      }
    });
  }

  function updateMilestone(data: MilestoneForm) {
    if (!milestone) {
      return;
    }

    MilestoneModel.update(milestone.id, {
      ...data,
    }).then((res) => {
      if (onUpdateMilestone) {
        onUpdateMilestone(res.data);
      }
    });
  }

  function renderFormField(name: string, label: string) {
    return (
      <div className="MilestoneDialog__form__field">
        <label className="MilestoneDialog__form__field__label" htmlFor={name}>
          {label}
        </label>
        <Input
          className="MilestoneDialog__form__field__control"
          fluid
          id={name}
          placeholder={label}
          {...register(name as any)}
        />
      </div>
    );
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} {...rest}>
      <div className="MilestoneDialog">
        <h2>{milestone ? 'Update' : 'Create'} Milestone</h2>
        <form className="MilestoneDialog__form" onSubmit={createOrUpdate}>
          <div className="MilestoneDialog__form__group">{renderFormField('name', 'Name')}</div>
          <div className="MilestoneDialog__form__actions">
            <Button onClick={onClose} variant="raised">
              Cancel
            </Button>
            <Button color="primary" type="submit" variant="raised">
              {milestone ? 'Save' : 'Create'}
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};
