import { User } from '~/src/features/auth';

export type Client = {
  id: string;
  name: string;
  slug: string;
  created_at: string;
  updated_at: string;
  is_internal: boolean;
  users: User[];
};

export type ClientUser = {
  id: string;
  created_at: string;
  updated_at: string;
  is_project_manager: boolean;
  client: string;
  user: string;
};
