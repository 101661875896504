import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Card, Container } from '~/src/ui';
import { ResetPasswordForm } from '../../components';

export const ResetPassword = () => {
  const { resetPasswordKey } = useParams();

  return (
    <>
      <Helmet>
        <title>Reset Password | Start Studio Portal</title>
      </Helmet>
      <Container>
        <div className="centerPage">
          <Card>
            <h1 className="text-center">Reset Password</h1>
            <p className="text-center my-8">Create a new password.</p>
            <ResetPasswordForm resetPasswordKey={resetPasswordKey} />
          </Card>
        </div>
      </Container>
    </>
  );
};
