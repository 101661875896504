import * as classNames from 'classnames';
import * as React from 'react';
import { Checkbox } from '../Checkbox/Checkbox';
import { Input, InputProps } from '../Input/Input';
import '../Input/Input.scss';
import { Popover, PopoverProps } from '../Popover/Popover';
import './MultiSelect.scss';

export type MultiSelectOption = {
  label: string;
  value: string;
};

export type MultiSelectProps = {
  className?: string;
  inputProps?: Omit<InputProps, 'onChange' | 'onClick' | 'readOnly' | 'value'>;
  onChange?: (value: string[]) => void;
  options?: MultiSelectOption[];
  popoverProps?: Omit<PopoverProps, 'isOpen' | 'onClose'>;
  style?: React.CSSProperties;
  value?: string[];
};

export const MultiSelect = ({
  className,
  inputProps,
  onChange,
  options,
  popoverProps,
  style,
  value,
}: MultiSelectProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  function getClassNames() {
    return classNames('MultiSelect', className);
  }

  function valueToString() {
    if (!value || value.length < 1) {
      return '';
    }
    return `${value.length} selected`;
  }

  return (
    <>
      <div className={getClassNames()} style={style}>
        <Input
          onClick={(e) => {
            setIsOpen(true);
          }}
          readOnly
          value={valueToString()}
          {...inputProps}
        />
        <Popover
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          {...popoverProps}
        >
          <div className="MultiSelect__popover">
            <div className="MultiSelect__popover__options">
              {options?.map((option) => {
                return (
                  <Checkbox
                    key={option.value}
                    checked={value?.includes(option.value)}
                    label={option.label}
                    onChange={() => {
                      if (value?.includes(option.value)) {
                        onChange?.(value.filter((v) => v !== option.value));
                      } else {
                        onChange?.([...(value || []), option.value]);
                      }
                    }}
                  />
                );
              })}
            </div>
          </div>
        </Popover>
      </div>
    </>
  );
};
