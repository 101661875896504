import * as React from 'react';
import { Helmet } from 'react-helmet';
import './App.scss';
import { AppLayout } from './layouts';
import { AppProvider } from './providers';

export const App = () => {
  return (
    <>
      <Helmet>
        <title>Start Studio Portal</title>
      </Helmet>
      <AppProvider>
        <AppLayout />
      </AppProvider>
    </>
  );
};
