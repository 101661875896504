import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { http } from '~/src/http';
import { Button, Input } from '~/src/ui';
import { useAuth } from '../../contexts';
import { ResendVerification } from '../ResendVerification/ResendVerification';

type LoginFormData = {
  username: string;
  password: string;
};

const defaultFormData: LoginFormData = {
  username: '',
  password: '',
};

export const LoginForm = () => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [resendVerifyOpen, setResendVerifyOpen] = React.useState(false);

  const { setUser } = useAuth();
  const { formState, getValues, handleSubmit, register, resetField } = useForm<LoginFormData>({
    defaultValues: defaultFormData,
  });
  const navigate = useNavigate();

  const onSubmit = handleSubmit((data) => {
    setErrorMessage('');
    http
      .post('/api/token-auth/', data)
      .then((res) => {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('userId', res.data.user.id);
        setUser(res.data.user);
        navigate('/');
      })
      .catch((err) => {
        resetField('password');

        if (!err.response.data) {
          return;
        }

        if (err.response.data.non_field_errors) {
          setErrorMessage(err.response.data.non_field_errors[0]);
        } else if (err.response.data.includes('verification')) {
          setErrorMessage(err.response.data);
          setResendVerifyOpen(true);
        } else {
          setErrorMessage('An error occurred. Please try again.');
        }
      });
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <Input fluid placeholder="Email" type="email" {...register('username', { required: true })} />
        <Input
          className="mt-4"
          fluid
          placeholder="Password"
          type="password"
          {...register('password', { required: true })}
        />
        {errorMessage && <p className="error">{errorMessage}</p>}
        <Button
          className="mt-4"
          color="primary"
          disabled={!formState.isValid}
          fluid
          testId="login-button"
          type="submit"
          variant="raised"
        >
          Login
        </Button>
      </form>
      <ResendVerification
        email={getValues('username')}
        isOpen={resendVerifyOpen}
        onClose={() => {
          setResendVerifyOpen(false);
        }}
      />
    </>
  );
};
