import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useCountdown } from '~/src/hooks';
import { Button, Container } from '~/src/ui';

export const Error = () => {
  const { remaining } = useCountdown({
    seconds: 5,
    onComplete: () => {
      window.location.replace('/');
    },
  });

  return (
    <>
      <Helmet>
        <title>Page Not Found | Start Studio Portal</title>
      </Helmet>
      <Container>
        <div className="mt-12 text-center">
          <h1>Page Not Found</h1>
          <p>You will be redirected to the home page in {remaining} seconds</p>
          <div>
            <Button color="primary" navigateTo={'/'}>
              Or Go Home Now!
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};
