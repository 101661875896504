import * as classNames from 'classnames';
import * as React from 'react';
import './Container.scss';

type ContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  testId?: string;
};

export const Container = ({ className, testId, ...rest }: ContainerProps) => {
  function getClassNames() {
    return classNames('Container', className);
  }

  return <div className={getClassNames()} data-testid={testId} {...rest} />;
};
