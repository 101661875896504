import * as classNames from 'classnames';
import * as React from 'react';
import '../Input/Input.scss';
import './Select.scss';

type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  filled?: boolean;
  fluid?: boolean;
  testId?: string;
};

export const Select = React.forwardRef(
  ({ className, filled, fluid, testId, ...rest }: SelectProps, ref: React.ForwardedRef<HTMLSelectElement>) => {
    function getContainerClassNames() {
      return classNames('Input__container', {
        'Input__container--fluid': fluid,
      });
    }

    function getClassNames() {
      return classNames(
        {
          'Input': !filled,
          'Input--filled': filled,
          'Input--fluid': fluid,
        },
        className
      );
    }

    return (
      <>
        <div className={getContainerClassNames()} data-testid={testId && `${testId}-container`}>
          <select className={getClassNames()} data-testid={testId} ref={ref} {...rest} />
        </div>
      </>
    );
  }
);
