import * as classNames from 'classnames';
import * as React from 'react';
import './Callout.scss';

export type CalloutProps = React.HTMLAttributes<HTMLDivElement> & {
  color?: 'black' | 'red' | 'orange' | 'yellow' | 'green' | 'blue' | 'indigo' | 'violet';
  header?: React.ReactNode;
  icon?: string;
  onDismiss?: () => void;
};

export const Callout = ({ children, className, color, header, icon, onDismiss, ...rest }: CalloutProps) => {
  function getClassNames() {
    return classNames(
      'Callout',
      {
        [`Callout--${color}`]: color,
        'Callout--withIcon': icon,
      },
      className
    );
  }

  return (
    <div className={getClassNames()} {...rest}>
      {icon && <span className="Callout__icon material-icons">{icon}</span>}
      {onDismiss && (
        <span
          className="Callout__icon Callout__icon--close material-icons"
          onClick={() => {
            onDismiss();
          }}
        >
          close
        </span>
      )}
      {header && <div className="Callout__header">{header}</div>}
      <div className="Callout__content">{children}</div>
    </div>
  );
};
