import * as React from 'react';
import { http } from '~/src/http';
import { Input, Menu, MenuItem, Popover, Spinner } from '~/src/ui';
import { debounceRef } from '~/src/utils/debounceRef';

type SearchPopoverProps = {
  endpoint: string;
  isOpen: boolean;
  onChange: (value: any) => void;
  onClose: () => void;
  parameter: string;
  popoverStyle?: React.CSSProperties;
  renderMatch: (match: any) => React.ReactNode;
  searchLabel?: string;
};

export const SearchPopover = (props: SearchPopoverProps) => {
  const [searchValue, setSearchValue] = React.useState('');
  const [matches, setMatches] = React.useState<any>([]);
  const [matchesLoading, setMatchesLoading] = React.useState(false);

  const debouncedSearch = debounceRef((value: string) => {
    setMatchesLoading(true);
    http
      .get(`${props.endpoint}?${props.parameter}=${value}`)
      .then((res) => {
        setMatches(res.data.results);
      })
      .finally(() => {
        setMatchesLoading(false);
      });
  }, 500);

  function renderMatches() {
    if (matchesLoading) {
      return (
        <div className="mt-4">
          <Spinner />
        </div>
      );
    }

    if (!searchValue || matches.length < 1) {
      return null;
    }

    return (
      <Menu className="mt-4">
        {matches.map((match: any, idx: number) => {
          return (
            <MenuItem
              key={idx}
              onClick={() => {
                props.onChange(match);
                props.onClose();
              }}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'left',
              }}
            >
              {props.renderMatch(match)}
            </MenuItem>
          );
        })}
      </Menu>
    );
  }

  return (
    <>
      <Popover isOpen={props.isOpen} onClose={props.onClose} style={props.popoverStyle}>
        <label className="block text-size-s mb-2">{props.searchLabel ? props.searchLabel : 'Search'}:</label>
        <Input
          autoFocus
          fluid
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            if (e.target.value.length > 0) {
              debouncedSearch(e.target.value);
            } else {
              setMatches([]);
            }
          }}
        />
        {renderMatches()}
      </Popover>
    </>
  );
};
