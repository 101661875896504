import * as React from 'react';
import * as Tagify from '@yaireo/tagify';
import { MixedTags } from '@yaireo/tagify/dist/react.tagify';
import './TagsTextarea.scss';

type TagsTextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {};

type Whitelist = {
  value: string;
  id: number;
  title: string;
  href?: string;
};

var whitelist_1: Whitelist[] = [
  { id: 101, value: 'Project 1', title: 'Project 1' },
  { id: 102, value: 'Project 2', title: 'Project 2' },
  { id: 103, value: 'Project 3', title: 'Project 3' },
  { id: 104, value: 'Project 4', title: 'Project 4' },
  { id: 105, value: 'Project 5', title: 'Project 5' },
  { id: 106, value: 'Project 6', title: 'Project 6' },
  { id: 107, value: 'Project 7', title: 'Project 7' },
  { id: 108, value: 'Project 8', title: 'Project 8' },
];

var whitelist_2: Whitelist[] = [
  { id: 201, value: 'Google', title: 'Google', href: 'https://google.com' },
  { id: 202, value: 'Twitter', title: 'Twitter', href: 'https://twitter.com' },
  { id: 203, value: 'Zeplin', title: 'Zeplin', href: 'https://zpl.io/5Eny6d8' },
  { id: 204, value: 'Zeplin detail 1', title: 'Zeplin', href: 'https://zpl.io/z8J1d77' },
  { id: 205, value: 'Zeplin detail 2', title: 'Zeplin', href: 'https://zpl.io/wq7W5yJ' },
];

const settings = {
  pattern: /@|#/,
  dropdown: { enabled: 1, mapValueTo: 'text', highlightFirst: true },
  whitelist: [...whitelist_1, ...whitelist_2],
};

export const TagsTextarea = (props: TagsTextareaProps) => {
  const [value, setValue] = React.useState<string>(
    'Here\'s a link to the app: [[{"id":202,"value":"Twitter","title":"Twitter","href":"https://twitter.com","prefix":"#"}]]'
  );
  const [whitelist, setWhitelist] = React.useState<Whitelist[]>(whitelist_1);

  const onChange = React.useCallback((e: CustomEvent<Tagify.ChangeEventData<Whitelist>>) => {
    // setValue(e.detail.value);
    console.log('CHANGED:', e.detail.value);
  }, []);

  const onInput = React.useCallback((e: CustomEvent<Tagify.InputEventData<Whitelist>>) => {
    if (e.detail['prefix'] === '@') {
      setWhitelist(whitelist_1);
    }
    if (e.detail['prefix'] === '#') {
      setWhitelist(whitelist_2);
    }
  }, []);

  const onClick = React.useCallback((e: CustomEvent<Tagify.ClickEventData<Whitelist>>) => {
    e.detail.data.href && window.open(e.detail.data.href, '_blank');
  }, []);

  return (
    <MixedTags
      autoFocus={true}
      settings={settings}
      className="TagsTextarea"
      onChange={onChange}
      onInput={onInput}
      onClick={onClick}
      value={value?.trim()}
      whitelist={whitelist}
    />
  );
};
