import * as classNames from 'classnames';
import * as React from 'react';
import './Toggle.scss';

export type ToggleLabelPosition = 'left' | 'right';

type ToggleProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  labelPosition?: ToggleLabelPosition;
  testId?: string;
};

export const Toggle = React.forwardRef(
  (
    { checked, className, label, labelPosition = 'left', testId, ...rest }: ToggleProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const containerClass = 'Toggle__container';

    function getClassNames() {
      return classNames('Toggle', className);
    }

    return (
      <>
        <label className={containerClass} data-testid={testId && `${testId}-container`}>
          {label && labelPosition === 'left' && label}
          <input
            checked={checked}
            className={getClassNames()}
            data-testid={testId}
            ref={ref}
            type="checkbox"
            {...rest}
          />
          <div className="Toggle__control"></div>
          {label && labelPosition === 'right' && label}
        </label>
      </>
    );
  }
);
