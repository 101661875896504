import * as React from 'react';
import { http } from '~/src/http';
import { Button, Dialog, DialogProps } from '~/src/ui';

type ResendVerificationProps = Omit<DialogProps, 'children'> & {
  email: string;
};

export const ResendVerification = ({ email, onClose, ...rest }: ResendVerificationProps) => {
  const [error, setError] = React.useState('');

  function clickResendVerifyEmail() {
    setError('');
    http
      .post('/api/resend-verify-email/', {
        email: email,
      })
      .then((res) => {
        if (onClose) {
          onClose();
        }
      })
      .catch((err) => {
        if (!err.response.data) {
          return;
        }

        if (err.response.data.non_field_errors) {
          setError(err.response.data.non_field_errors[0]);
        } else {
          setError('An error occurred. Please try again.');
        }
      });
  }

  return (
    <Dialog onClose={onClose} {...rest}>
      <h4>Email Verification Required</h4>
      <p>
        Please check <strong>{email}</strong> for a verification email from us.
      </p>
      <Button color="primary" onClick={clickResendVerifyEmail} testId="resend-button" variant="raised">
        Resend Verification Email
      </Button>
      {error && <p>{error}</p>}
    </Dialog>
  );
};
