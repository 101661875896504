import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input } from '~/src/ui';
import { UserModel } from '../../api';

type SignUpFormProps = {
  onSuccess?: () => void;
};

type SignUpFormData = {
  email: string;
  password1: string;
  password2: string;
};

const defaultFormData: SignUpFormData = {
  email: '',
  password1: '',
  password2: '',
};

export const SignUpForm = (props: SignUpFormProps) => {
  const [errorMessage, setErrorMessage] = React.useState('');

  const { formState, handleSubmit, register, resetField } = useForm<SignUpFormData>({
    defaultValues: defaultFormData,
  });

  const onSubmit = handleSubmit((data) => {
    setErrorMessage('');
    UserModel.create(data)
      .then(() => {
        if (props.onSuccess) {
          props.onSuccess();
        }
      })
      .catch((err) => {
        resetField('password1');
        resetField('password2');
        const { email, non_field_errors } = err.response.data;

        if (email) {
          setErrorMessage(email[0]);
        } else if (non_field_errors) {
          setErrorMessage(non_field_errors[0]);
        } else {
          setErrorMessage('An error occurred. Please try again.');
        }
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Input fluid placeholder="Email" type="email" {...register('email', { required: true })} />
      <Input
        className="mt-4"
        fluid
        placeholder="Password"
        type="password"
        {...register('password1', { required: true })}
      />
      <Input
        className="mt-4"
        fluid
        placeholder="Password (Again)"
        type="password"
        {...register('password2', { required: true })}
      />
      <h6 className="hint">Passwords must be at least 8 characters, with at least one number and one letter.</h6>
      {errorMessage && <p className="error">{errorMessage}</p>}
      <Button
        className="mt-4"
        color="primary"
        disabled={!formState.isValid}
        fluid
        testId="signup-button"
        type="submit"
        variant="raised"
      >
        Sign Up
      </Button>
    </form>
  );
};
