import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Dialog, DialogProps, Input } from '~/src/ui';
import { EstimateSectionModel } from '../../api';
import { Estimate, EstimateSection } from '../../types';
import './EstimateSectionDialog.scss';

export type EstimateSectionDialogProps = DialogProps & {
  estimate?: Estimate;
  section?: EstimateSection;
  onClose: () => void;
  onCreateSection: (section: EstimateSection) => void;
  onUpdateSection: (section: EstimateSection) => void;
};

export type EstimateSectionForm = {
  name: string;
};

export const EstimateSectionDialog = ({
  estimate,
  section,
  onClose,
  onCreateSection,
  onUpdateSection,
  ...rest
}: EstimateSectionDialogProps) => {
  const { handleSubmit, register, reset, setValue } = useForm<EstimateSectionForm>();

  React.useEffect(() => {
    if (!section) {
      reset();
      return;
    }
    setValue('name', section.name);
  }, [section]);

  const createOrUpdate = handleSubmit((data) => {
    if (!section) {
      createSection(data);
    } else {
      updateSection(data);
    }
  });

  function createSection(data: EstimateSectionForm) {
    if (!estimate) {
      return;
    }

    EstimateSectionModel.create({
      estimate: estimate.id,
      name: data.name,
    }).then((newSection) => {
      reset();
      onCreateSection(newSection.data);
    });
  }

  function updateSection(data: EstimateSectionForm) {
    if (!section) {
      return;
    }

    EstimateSectionModel.update(section.id, {
      name: data.name,
    }).then((updatedSection) => {
      reset();
      onUpdateSection(updatedSection.data);
    });
  }

  return (
    <Dialog onClose={onClose} {...rest}>
      <div className="EstimateSectionDialog">
        <h3>{section ? 'Update' : 'Create'} Section</h3>
        <form className="EstimateSectionDialog__form" onSubmit={createOrUpdate}>
          <label>Name</label>
          <Input
            {...register('name')}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                createOrUpdate();
                e.preventDefault();
              }
            }}
          />
          <div className="EstimateSectionDialog__form__actions">
            <Button
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
              variant="raised"
            >
              Cancel
            </Button>
            <Button color="primary" type="submit" variant="raised">
              Save
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};
