import * as React from 'react';
import { Nav, SideNav } from '~/src/components';
import { useAuth } from '~/src/features/auth';
import { AppRoutes } from '~/src/routes';
import { Toaster } from '~/src/ui';
import './AppLayout.scss';

export const AppLayout = () => {
  const [isSideNavOpen, setIsSideNavOpen] = React.useState(true);

  const { user } = useAuth();

  return (
    <>
      <Toaster />
      <div className="AppLayout">
        <Nav
          onToggleSideNav={() => {
            setIsSideNavOpen(!isSideNavOpen);
          }}
        />
        <div className="AppLayout__flex">
          {user && isSideNavOpen && <SideNav />}
          <div className="AppLayout__main">
            <AppRoutes />
          </div>
        </div>
      </div>
    </>
  );
};
