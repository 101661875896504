import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { ConfirmDialog } from '~/src/components';
import { Button, Container, Spinner } from '~/src/ui';
import { MilestoneModel, ProjectModel } from '../../api';
import { MilestoneDialog, ProjectDrawer } from '../../components';
import { Milestone, Project } from '../../types';
import './ProjectDetails.scss';

type urlObj = {
  key: keyof Project;
  label: string;
};

export const externalUrls: urlObj[] = [
  { key: 'slack_url', label: 'Slack' },
  { key: 'drive_folder_url', label: 'Drive folder' },
  { key: 'harvest_url', label: 'Harvest' },
];

export const liveSiteUrls: urlObj[] = [
  { key: 'staging_url', label: 'Staging' },
  { key: 'production_url', label: 'Production' },
];

export const designUrls: urlObj[] = [
  { key: 'figma_url', label: 'Figma' },
  { key: 'zeplin_url', label: 'Zeplin' },
];

export const repoUrls: urlObj[] = [
  { key: 'frontend_repo_url', label: 'Frontend Repo' },
  { key: 'backend_repo_url', label: 'Backend Repo' },
  { key: 'flutter_repo_url', label: 'Flutter Repo' },
  { key: 'android_repo_url', label: 'Android Repo' },
  { key: 'ios_repo_url', label: 'iOS Repo' },
];

export const ProjectDetails = () => {
  const [project, setProject] = React.useState<Project>();
  const [milestones, setMilestones] = React.useState<Milestone[]>([]);
  const [projectDrawer, setProjectDrawer] = React.useState(false);
  const [milestoneDialog, setMilestoneDialog] = React.useState(false);
  const [milestoneDeleteId, setMilestoneDeleteId] = React.useState<string>();

  const { projectSlug } = useParams();

  React.useEffect(() => {
    if (!projectSlug) {
      return;
    }

    ProjectModel.get(projectSlug).then((project) => {
      setProject(project.data);

      MilestoneModel.list({
        project: projectSlug,
      }).then((milestones) => {
        setMilestones(milestones.data.results);
      });
    });
  }, [projectSlug]);

  const reorder = (initArr: any[], startIndex: number, endIndex: number) => {
    const result = initArr;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function handleDragEnd(result: any) {
    if (!result.destination) return;
    setMilestones((prev) => reorder(prev, result.source.index, result.destination.index));
    MilestoneModel.detailAction(result.draggableId, 'move', 'post', { to: result.destination.index }).then((res) => {
      setMilestones(res.data);
    });
  }

  function deleteMilestone(id: string) {
    MilestoneModel.delete(id).then(() => {
      setMilestones((p) => p.filter((m) => m.id !== id));
    });
  }

  function renderUrls(urlList: { key: string; label: string }[], label: string) {
    urlList = urlList.filter((url) => project?.[url.key]);
    return urlList.length ? (
      <div className="ProjectDetails__section">
        <h2 className="ProjectDetails__section__title">{label}</h2>
        {urlList.map((url, index) => (
          <a key={index} href={project?.[url.key]} target="_blank">
            {url.label}
          </a>
        ))}
      </div>
    ) : null;
  }

  if (!project) {
    return (
      <Container>
        <Spinner message="Loading project..." />
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{project.name} | Start Studio Portal</title>
      </Helmet>
      <Container>
        <div className="ProjectDetails">
          <div className="ProjectDetails__header">
            <h1>{project.name}</h1>
            <Button
              iconLeading="edit"
              onClick={() => {
                setProjectDrawer(true);
              }}
              variant="outlined"
            >
              Edit
            </Button>
          </div>
          <div className="ProjectDetails__section">
            <h2 className="ProjectDetails__section__title">Client</h2>
            <p>{project.client_name}</p>
          </div>
          <div className="ProjectDetails__section">
            <h2 className="ProjectDetails__section__title">Milestones</h2>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={project.id}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {milestones.map((milestone, index) => (
                      <Draggable draggableId={milestone.id} index={index} key={milestone.id}>
                        {(provided) => (
                          <div
                            className="ProjectDetails__milestone__container"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="ProjectDetails__milestone">
                              <div>{milestone.name}</div>
                              <div className="ProjectDetails__milestone__actions">
                                <Button
                                  color="red"
                                  onClick={() => {
                                    setMilestoneDeleteId(milestone.id);
                                  }}
                                >
                                  Delete
                                </Button>
                                <Button navigateTo={`/milestones/${milestone.id}`}>View Test Plan</Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Button
              color="primary"
              onClick={() => {
                setMilestoneDialog(true);
              }}
            >
              Add Milestone
            </Button>
          </div>
          {renderUrls(externalUrls, 'External Urls')}
          {renderUrls(liveSiteUrls, 'Live Site Urls')}
          {renderUrls(designUrls, 'Design Urls')}
          {renderUrls(repoUrls, 'Repo Urls')}
        </div>
      </Container>
      <ProjectDrawer
        isOpen={projectDrawer}
        onClose={() => {
          setProjectDrawer(false);
        }}
        onUpdateProject={(project) => {
          setProject(project);
          setProjectDrawer(false);
        }}
        project={project}
      />
      <MilestoneDialog
        isOpen={milestoneDialog}
        onClose={() => {
          setMilestoneDialog(false);
        }}
        onCreateMilestone={(milestone) => {
          setMilestones((p) => [...p, milestone]);
          setMilestoneDialog(false);
        }}
        project={project}
      />
      <ConfirmDialog
        danger
        isOpen={!!milestoneDeleteId}
        onClose={() => {
          setMilestoneDeleteId(undefined);
        }}
        onConfirm={() => {
          if (!milestoneDeleteId) {
            return;
          }

          deleteMilestone(milestoneDeleteId);
          setMilestoneDeleteId(undefined);
        }}
        message="Are you sure you want to delete this milestone?"
        title="Delete Milestone"
      />
    </>
  );
};
